import React, { useState, useEffect,useRef } from "react";
import "./Question.css";
import Emoji from "../EmojiScreen/Emoji";
import io from 'socket.io-client';
import { useParams } from "react-router-dom";
import { Question_data } from "../DemoQuestion/DemoQuestion";
import BouncingImage from "../FinalScreen/BouncingImage";
import { decryptText } from "../../Utiles";
import { userAllPost, UserProfilePic } from "../../AllApis/ApiIntegrate";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
// const socket = io(process.env.REACT_APP_SOCKET_URL);
export default function Question({ user_name, users, setReff_Emoji, setEmoji_show, owner_user_id,setVisible_Screen2,setIsModalOpen,isModalOpen,user_id_by_database,view_pic }) {
  const queryParams = new URLSearchParams(window.location.search);
  const url_share_or_not = queryParams.get("share");
  const user_create_id = queryParams.get("user_create_id");
  const user_name_query = queryParams.get("user_name");
  const { id } = useParams();
  const socket = useRef();
  const user_id = useSelector(state => state.myReducer.data)
  let userId = Object.keys(user_id).length !== 0 ? user_id.user_id : ''
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [animation, setAnimation] = useState("slide-in-left");
  const [visible_final_screen, setVisible_Screen] = useState(false)

  const [question_data, setQuestion_data] = useState({})
  const [next_question_data, setNext_Question_data] = useState({})
  const [questions, setQuestions] = useState([])
  console.log(user_id, "user_id<+M", userId);
  const [loader, setLoader] = useState(false)
  const Question_Dataget = async () => {
    setLoader(true)
    try {
      let res = await userAllPost(userId ? userId :owner_user_id)
      setQuestions(res.data.data.questions)
      setLoader(false)
    } catch (err) {
      setQuestions([])
      setLoader(false)
    }
  }
  useEffect(() => {
    if (owner_user_id || userId) {
      Question_Dataget()
    }
  }, [owner_user_id, userId])
  let ques_data = questions.length !== 0 ? questions : Question_data()
  const handleNext = () => {
    setQuestion_data({})
    setReff_Emoji(new Date().getMilliseconds())
    // if (!selectedAnswer) {
    //   alert("Please select an answer before proceeding.");
    //   return;
    // }

    // Trigger slide-out animation
    if (question_data?.create_question_sender_id) {
      if (ques_data.length > currentQuestionIndex + 1) {
        setAnimation("slide-out-left");
        setTimeout(() => {
          // Update to the next question
          socket.current.emit('sendNextQuestion', id, ((currentQuestionIndex + 1) % ques_data.length))
          setCurrentQuestionIndex(
            (prevIndex) => (prevIndex + 1) % ques_data.length
          );
          setSelectedAnswer("");
          setAnimation("slide-in-left");
        }, 500);
      } else {
        setVisible_Screen(true)
        setVisible_Screen2(true)
        setEmoji_show(false)
        return
      }
    } else {
      return
    }
  };

  const handleQuestion = (value) => {
    socket.current.emit('sendQuestionAnswer', id, value, user_create_id || '');
  }

  const handleAnswerChange = (value) => {
    if (user_create_id) {
      if (Object.keys(question_data).length == 0) {
        return
      } else {
        if (question_data.create_question_sender_id === '') {
          setSelectedAnswer(value);
          handleQuestion(value)
        } else {
          return
        }
      }
    } else {
      setSelectedAnswer(value);
      handleQuestion(value)
    }
  };

  console.log(selectedAnswer, question_data, 'question_data.message');

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current.on('receiveQuestionAnswer', (room_id, message, create_question_sender_id) => {
      if (room_id === id) {
        setQuestion_data({ room_id, message, create_question_sender_id })


      }

    });
  }, []);



  useEffect(() => {
    socket.current.on('receiveNextQuestion', (room_id, question_id) => {
      console.log(currentQuestionIndex, question_id, "Mrinmay Manan");

      if (room_id === id) {
        setNext_Question_data({ room_id, question_id })

      }

    });
  }, []);


  useEffect(() => {
    if (user_create_id) {
      if (Object.keys(next_question_data).length !== 0) {

        handleNext()

      }
    }
  }, [Object.keys(next_question_data).length !== 0 && next_question_data?.question_id])


  //calculate data
  const [calculate_data, setCalculate_data] = useState([])
  const [calculate_question, setCalculate_question] = useState('')

  let totalQuestions = ques_data.length;
  // Count the number of type: false
  let falseCount = calculate_data.filter(q => q !== false).length;
  // Determine the result
  let result;
  const calculatedPercentage = (falseCount / totalQuestions) * 100;
  result=calculatedPercentage
  // if (falseCount / totalQuestions > 0.5) {
  //   result = "bad";
  // } else if (falseCount / totalQuestions > 0.2) {
  //   result = "average";
  // } else {
  //   result = "good";
  // }


  useEffect(() => {
    if (Object.keys(question_data).length !== 0 && question_data.create_question_sender_id !== '')
      if (selectedAnswer !== question_data.message) {
        setCalculate_data([...calculate_data, false])
      } else {
        setCalculate_data([...calculate_data, true])
      }

  }, [question_data])

  const handleFinnishQuestion = () => {
    if (question_data?.create_question_sender_id) {
      socket.current.emit('sendFinnishQuestion', id, true, result)
    } else {
      return
    }
  }
  useEffect(() => {
    socket.current.on('receiveFinnishQuestion', (room_id, question_finnish, calculate_data) => {
      if (room_id === id) {
        if (question_finnish) {
          setVisible_Screen(true)
          setVisible_Screen2(true)
          setEmoji_show(false)
          setCalculate_question(calculate_data)
        }

      }

    });
  }, []);

   function changeStyles() {
     
      let elements = document.querySelectorAll(".modal-trigger_profile2");
      elements.forEach(element => {
      element.style.top = '30%'; 
      });
      }
    function changeStyles2() {
     
      let elements = document.querySelectorAll(".modal-trigger_profile2");
      elements.forEach(element => {
      element.style.top = '36%'; 
      });
      }
      useEffect(()=>{
        if(question_data.create_question_sender_id){
        changeStyles()
        }else{
        changeStyles2()
        }
      },[question_data.create_question_sender_id,visible_final_screen])

  console.log( "Mrinmay2.0",totalQuestions,falseCount);
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [popupImageUrl, setPopupImageUrl] = useState("");
   useEffect(() => {
      const Pic = async () => {
        let res = await UserProfilePic(user_id_by_database)
        setPopupImageUrl(res.data.user_details.profile_img || `${window.location.origin}/default_profile.webp`)
      }
      if (user_id_by_database) {
        Pic()
        // setName(users[1]?.username)
      }
    }, [users?.length, isModalOpen])
  return (
    <div>


      {visible_final_screen ? (
        <BouncingImage calculate_question={calculate_question} users={users} />

      ) : (
        <>
        {user_name_query &&!users[1]?.username &&<div style={{marginBottom:"7px",marginLeft:"9px"}}><span style={{ fontStyle: "italic", color: "black",fontSize:"large"}}>Waiting for your partner to join.</span></div>}
        {/* <p
        >hfhfhh</p> */}
          {!question_data.create_question_sender_id && (
            <>
              {Object.keys(question_data).length == 0 && (
                <>
                  {user_create_id && Object.keys(question_data).length == 0 ? (
                    <>
                      {/* {users[1]?.username ? <h3>Its {users[1]?.username} trun</h3> : ""} */}
                      {users[1]?.username ? <p style={{ fontSize: "26px" ,marginLeft:"9px",marginBottom:"40px"}}>It's <span style={{ fontStyle: "italic", color: "blue" }}>{users[1]?.username}'s</span> turn.</p>
                        : ""}
                    </>
                  ) : (
                    <p style={{ fontSize: "26px",marginLeft:"9px" ,marginBottom:"40px"}}>It's <span style={{ fontStyle: "italic", color: "blue" }}>your</span> turn.</p>
                    // <h3>Its your trun</h3>
                  )}
                </>
              )}
              {Object.keys(question_data).length !== 0 && (
                <>
                  {user_create_id ? (
                    // <h3>Its your trun</h3>
                    <p style={{ fontSize: "26px",marginLeft:"9px" ,marginBottom:"40px"}}>It's <span style={{ fontStyle: "italic", color: "blue" }}>your</span> turn.</p>

                  ) : (
                    <>
                      {/* {users[1]?.username ? <h3>Its {users[0]?.username} trun</h3> : ""} */}
                      {users[1]?.username ? <p style={{ fontSize: "26px",marginLeft:"9px" ,marginBottom:"40px"}}>It's <span style={{ fontStyle: "italic", color: "blue" }}>{users[0]?.username}'s</span> turn.</p>
                        : ""}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {loader ? (
            <Loader show={"false"} />

          ) : (

    <div class="card">
      {view_pic&&(
      <img src={popupImageUrl} alt="Profile" class="profile-img" onClick={()=>toggleModal()}></img>
          )}
            <div className="container">
              
              <div className={`question-card ${animation}`}>
                <p id="question">{ques_data[currentQuestionIndex].question}</p>
                <div className="options-row">
                  <button
                    // className={`option-card ${selectedAnswer === ques_data[currentQuestionIndex].OptionA ? "selected" : ""
                    //   }`}
                    style={{fontWeight:900}}
                    className={`option-card ${(question_data.create_question_sender_id && ques_data[currentQuestionIndex].OptionA === question_data.message) ? "selected_right" : (question_data.create_question_sender_id && ques_data[currentQuestionIndex].OptionA !== question_data.message) ? "selected_wrong" : ques_data[currentQuestionIndex].OptionA === question_data.message ? "selected" : ""
                      }`}
                    onClick={() => handleAnswerChange(ques_data[currentQuestionIndex].OptionA)}

                  >
                    {/* <div className="custom-radio"></div> */}
                    {ques_data[currentQuestionIndex].OptionA}
                  </button>
                  <button
                   style={{fontWeight:900}}
                    className={`option-card ${(question_data.create_question_sender_id && ques_data[currentQuestionIndex].OptionB === question_data.message) ? "selected_right" : (question_data.create_question_sender_id && ques_data[currentQuestionIndex].OptionB !== question_data.message) ? "selected_wrong" : ques_data[currentQuestionIndex].OptionB === question_data.message ? "selected" : ""
                      }`}
                    onClick={() => handleAnswerChange(ques_data[currentQuestionIndex].OptionB)}
                  >
                    {/* <div className="custom-radio"></div> */}
                    {ques_data[currentQuestionIndex].OptionB}
                  </button>
                </div>
                {url_share_or_not == null ? (
                  <span style={{fontStyle:"italic",color:"black",fontSize:"12px"}}>" Only your partner can select the next question."</span>
                ) : (
                  <>
                    {ques_data.length > currentQuestionIndex + 1 ? (
                      <button className={question_data.create_question_sender_id?"next-btn":'disable_next_btn'} onClick={handleNext}>
                        Next
                      </button>
                    ) : (
                      <button className={question_data.create_question_sender_id?"next-btn":'disable_next_btn'} onClick={handleFinnishQuestion}>
                        Finnish
                      </button>
                    )}
                  </>

                 )} 

              </div>
            </div>
            </div>
          )}

        </>
      )}
      {/* <Emoji/> */}
    </div>
  );
}
