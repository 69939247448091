import apiUrl from "../ApiAxios";

export const userRegister=(data)=>apiUrl.post(`/api/user/newuser`,data)
export const userLogin=(data)=>apiUrl.post(`/api/user/login`,data)
export const verifytoken=async(token)=>await apiUrl.post(`/api/user/verifytoken`,{
  headers: {
    'Authorization':token,
  },
})
export const forgetPassword=(email)=>apiUrl.post(`/api/user/forgetpassword`,email)
export const resetPassword=(json)=>apiUrl.post(`/api/user/resetpassword`,json)
export const CheckOtp=(email,otp)=>apiUrl.post(`/api/user/check_otp?email=${email}&otp=${otp}`)
export const userNewPost=(data)=>apiUrl.post(`/api/userpost/newpost`,data)
export const userAllPost=(user_id)=>apiUrl.post(`/api/userpost/getallpost/${user_id}`)
export const ProfilePicUpdate=(data)=>apiUrl.put(`/api/user/profilepic/upload`,data)
export const UpdateUserDetails=(data)=>apiUrl.put(`/api/user/updateuser`,data)
export const UserProfilePic=(user_id)=>apiUrl.post(`/api/user/userprofile/${user_id}`)
export const CreateScore=(json)=>apiUrl.post(`/api/userpost/score`,json)
export const GetScore=(postId)=>apiUrl.post(`/api/userpost/getscore?postId=${postId}`)