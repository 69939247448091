import React from "react";
import "./BottomRight.css";
import { FaPlus } from "react-icons/fa"
const BottomRight = ({toggleModal}) => {
  return (
    <div className="bottom-right" onClick={toggleModal}>
    <div className="icon-container">
      <FaPlus className="icon" />
      <span className="hover-text">Create your own questions.</span>
    </div>
  </div>
  );
};

export default BottomRight;
