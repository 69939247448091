import React, { useState } from "react";
import "./CustomerSupport.css";
import axios from "axios";
const CustomerSupport = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
     const[load,setLoad]=useState(false)
    const toggleSupport = () => {
      setIsOpen((prev) => !prev);
      setEmail("");
      setName("");
      setMessage("");
    };
    const handleSubmit = async(event) => {
      event.preventDefault();
      if (!name || !email || !message) {
        return;
      }
      try{
        setLoad(true)
      await axios.post(`${process.env.REACT_APP_SOCKET_URL}/send_contact_mails`,{
        emails:[email],
        name:name,
        feedback:message
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      }
      })
      setEmail('')
      setMessage('')
      setName('')
      setLoad(false)
    
    }catch(err){
    
    }
      toggleSupport();
    };
    return (
      <div>
        {isOpen ? (
          <div className="customer-support-modal">
            <div className="customer-support-header">
              <h3>Contact Us</h3>
              <button className="close-btn" onClick={toggleSupport}>
                ×
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="feedback_contact-container">
                <div class="feedback_input-group">
                  <label for="name">Your Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Enter your name"
                  />
                </div>
                <div class="feedback_input-group">
                  <label for="email">Your Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Enter your email"
                  />
                </div>
                <div class="feedback_input-group">
                  <label for="message">Your Message</label>
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    placeholder="Enter your message"
                  ></textarea>
                </div>
              </div>
              <div className="customer-support-footer">
                <button type="submit" class="feedback_button">
                 {!load?'Send Message':'Sending...'}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <button className="open-btn" onClick={toggleSupport} title="Contact Us"style={{userSelect:"none"}}>
             <i className="fa fa-commenting" style={{ borderRadius: "38px",height:"42px",width:"44px",fontSize:"24px",color:"#3a39e4db",userSelect:"none",marginTop:"15px" }}></i>
            {/* <img
              src="https://img.freepik.com/free-vector/blue-notification-message-chat-icon-attention-sms-sign-internet-message-3d-rendering_40876-3450.jpg?t=st=1739344268~exp=1739347868~hmac=f49222f299e15eed351f5cb5769e030b85622bbff1fa5831aafe9a8003cd3c0b&w=1800"
              alt=""
              style={{ borderRadius: "38px",height:"53px" }}
              width="55px"
            /> */}
          </button>
        )}
      </div>
    );
  };
  export default CustomerSupport;