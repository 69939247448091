import React, { useState, useEffect, useRef } from 'react';
import './ChatPopup.css'; // External CSS file to match your styles
import TopRightChat from '../TopRightChat';
import { FaWindowMinimize } from "react-icons/fa"
import AutoGrowTextarea from "../AutogrowInput/AutoGrowTextarea"
import { decryptText, encryptText, formatURL, isValidLink } from '../../Utiles';
import { io } from "socket.io-client";
import { useParams } from 'react-router-dom';
const socket = io(process.env.REACT_APP_SOCKET_URL);
function ChatPopup() {
  const { id } = useParams();
  // const socket = useRef();
  console.log(id, 'this is message id');
  const queryParams = new URLSearchParams(window.location.search);
  const user_create_id = queryParams.get("user_create_id") || '';
  // const socket = useRef(); 
  const scrollRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [inputMessage, setInputMessage] = useState('');
  const [unseen_message, setUnseen_message] = useState([])
  const [isClosing, setIsClosing] = useState(false);
  const toggleChatPopup = () => {
    setUnseen_message([])
    if (isOpen) {
      setIsClosing(true); // Start closing animation
      setTimeout(() => {
        setIsOpen(false); // After animation, set isOpen to false
        setIsClosing(false); // Reset closing state
      }, 300); // Match with CSS transition time
    } else {
      setIsOpen(true); // Open the popup immediately
    }
  };
  function changeStyles() {

    let elements = document.querySelectorAll(".modal-trigger_profile2");
    elements.forEach(element => {
      element.style.zIndex = -1;
    });
  }
  function changeStyles2() {

    let elements = document.querySelectorAll(".modal-trigger_profile2");
    elements.forEach(element => {
      element.style.zIndex = 0;
    });
  }
  useEffect(() => {
    if (isOpen) {
      changeStyles()
    } else {
      changeStyles2()
    }
  }, [isOpen])

  //.......................//
  const modalRef = useRef(null);

  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [Messagestatus, setMessagestatus] = useState("")
  const [writemessage, setMessage] = useState("")
  const correctSecretCode = id;
  const base64 = encryptText(writemessage, correctSecretCode)

  const [messages, setMessages] = useState([]);
  // { sender: 'receiver', text: 'Hello! How can I help you today?' },
  // { sender: 'sender', text: 'Hi! I have a question about my order.' },
  useEffect(() => {
    arrivalMessage && setMessages((prev) => [arrivalMessage, ...prev]);
  }, [arrivalMessage]);

  useEffect(() => {
    if (!isOpen) {
      if (arrivalMessage?.sender === 'receiver') {
        arrivalMessage && setUnseen_message([...unseen_message, arrivalMessage.text]);
      }
    }
  }, [arrivalMessage]);
  useEffect(() => {
    // window.scrollTo(0, document.getElementsByClassName("chat"))
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, Messagestatus]);
  const handleSend = async () => {
    try {
      if (writemessage.trim() !== "") {

        // setMessages([
        //   ...messages,
        //   { sender: 'sender', text: base64 }
        // ]);
        socket.emit('sendMessageToRoom', user_create_id || '', base64, id)
        // socket.current.emit("send-msg", {
        //   to: user_create_id||'',
        //   messagetext:base64,
        //   messageid:id
        // });
        setArrivalMessage({ sender: 'sender', text: base64 });

        setMessagestatus(new Date().getMilliseconds())
        setMessage("")


      }

    } catch (err) {
      console.log(err)
    }

  }


  useEffect(() => {
    // socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.on('receiveMessage', (user_id, message, room_id) => {
      if (room_id === id) {
        if (user_id !== user_create_id) {
          setArrivalMessage({ sender: 'receiver', text: message })

        }

      }

    });
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const chatBodyRef = useRef(null);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const scrollThreshold = 100
  useEffect(() => {
    const adjustChatHeight = () => {
      if (isOpen) {
        document.documentElement.style.setProperty("--chat-height", `${window.visualViewport.height * 0.8}px`);
      }
    };
    window.visualViewport?.addEventListener("resize", adjustChatHeight);
    window.visualViewport?.addEventListener("scroll", adjustChatHeight);
    return () => {
      window.visualViewport?.removeEventListener("resize", adjustChatHeight);
      window.visualViewport?.removeEventListener("scroll", adjustChatHeight);
    };
  }, [isOpen]);
  // const closeChat = () => setIsOpen(false);
  // const handleTouchStart = (e) => {
  //   startY.current = e.touches[0].clientY;
  //   isDragging.current = true;
  // };
  const closeChat = () => {
    if (chatBodyRef.current) {
      // chatBodyRef.current.style.transform = "translateY(0%)";
      // chatBodyRef.current.style.opacity = "0";
      setTimeout(() => setIsOpen(false), 300);
    }
  };
  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
    isDragging.current = true;
  };
  const handleTouchMove = (e) => {
    if (!isDragging.current || !chatBodyRef.current) return;
    let moveY = e.touches[0].clientY;
    let deltaY = moveY - startY.current;
    if (deltaY > 0) {
      // Move downward only
      chatBodyRef.current.style.transform = `translateY(${deltaY}px)`;
    }
    if (deltaY > scrollThreshold) {
      // If swiped down enough, close modal
      closeChat();
      isDragging.current = false;
    }
  };
  const handleTouchEnd = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.style.transform = "translateY(0)";
    }
    isDragging.current = false;
  };
  // const handleTouchMove = (e) => {
  //   if (!isDragging.current) return;
  //   let moveY = e.touches[0].clientY;
  //   if (moveY - startY.current > 50) {
  //     closeChat();
  //     isDragging.current = false;
  //   }
  // };
  useEffect(()=>{
    if(!isMobile){
      setIsOpen(true)
    }
  },[isMobile])
  return (
    <>
      {isMobile ? (
        <>
          <TopRightChat toggleChatPopup={toggleChatPopup} unseen_message={unseen_message} />
          <div className={`chat-modal ${isOpen ? "show" : ""}`}>
            <div className="chat-header"  ref={chatBodyRef} onTouchStart={handleTouchStart} 
            onTouchMove={handleTouchMove}  
            onTouchEnd={handleTouchEnd}>
              <h2 style={{ fontSize: "20px" }}>Chat</h2>
              <div className="chat-close-tab" ></div>
              {/* <div className="chat-close-tab" onClick={closeChat}></div> */}
              <button className="close-btn" onClick={closeChat}>&times;</button>
            </div>
            <div className="chat-body" ref={chatBodyRef}>
              {messages.length == 0 ? (
                <>
                  <h3 style={{ color: "black" }}>One time chat</h3>
                </>
              ) : (
                <>
                  {messages.map((message, index) => (
                    //  <div key={index} className={`chat-message ${message.sender ?"user": "bot" }`}>{message.sende}{decryptText(message.text, correctSecretCode)}</div>
                    <div key={index} className={`message ${message.sender}`}>
                      {isValidLink(decryptText(message.text, correctSecretCode)) ? (
                        <a href={formatURL(decryptText(message.text, correctSecretCode))} target='_blank' style={{ color: "white" }}>{decryptText(message.text, correctSecretCode)}</a>

                      ) : (
                        <>
                          {decryptText(message.text, correctSecretCode)}
                        </>
                      )}

                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="chat-footer">
              <AutoGrowTextarea Onsubmit={handleSend} setMessage={setMessage} isOpen={isOpen} />
              {/* <input 
              type="text" 
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)} 
              placeholder="Type a message..." 
            />
            <button onClick={sendMessage}>Send</button> */}
              <div ref={scrollRef}></div>
            </div>
          </div>
          <style jsx>{`
        :root {
          --chat-height: 80vh;
        }
        .chat-open-btn {
          position: fixed;
          bottom: 20px;
          right: 20px;
          background: #007bff;
          color: white;
          padding: 12px 20px;
          border: none;
          border-radius: 50px;
          font-size: 16px;
          cursor: pointer;
        }
        .chat-modal {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          height: var(--chat-height);
          background: white;
          transform: translateY(100%);
          transition: transform 0.3s ease-in-out;
          display: flex;
          flex-direction: column;
          border-radius: 20px 20px 0 0;
        }
        .chat-modal.show {
          transform: translateY(0);
        }
        .chat-header {
          background: #007bff;
          color: white;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 20px 20px 0 0;
          height: 40px;
        }
        .chat-close-tab {
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 5px;
          background: white;
          border-radius: 5px;
        }
        .chat-body {
          flex: 1;
          align-items: anchor-center;
        }
        .chat-message {
          background: #007bff;
          color: white;
          padding: 10px;
          border-radius: 10px;
          margin-bottom: 10px;
          max-width: 70%;
          word-wrap: break-word;
        }
        .chat-message.user {
          align-self: flex-end;
          background: #28a745;
        }
        .chat-footer {
          display: flex;
          padding: 10px;
          background: #ffffff;
          border-top: 1px solid #ddd;
        }
        .chat-footer input {
          flex: 1;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .send-btn {
          background: #007bff;
          color: white;
          border: none;
          padding: 10px 15px;
          margin-left: 10px;
          border-radius: 5px;
          cursor: pointer;
        }
      `}</style>

        </>
      ) : (
        <div className="chat-container">
          <TopRightChat toggleChatPopup={toggleChatPopup} unseen_message={unseen_message} />
          {/* <button className="chat-button" onClick={toggleChatPopup}>Chat</button> */}
          <div className={`chat-popup ${isOpen ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
            <div className="chat-header">
              Chat
              {/* <button className="close-button" onClick={toggleChatPopup}>-</button> */}
              <FaWindowMinimize className="icon close-button" onClick={toggleChatPopup} />
            </div>
            <div className="chat-body" style={{ justifyContent: messages.length == 0 ? "center" : '', alignItems: messages.length == 0 ? "center" : "" }}>
              {messages.length == 0 ? (
                <>
                  <h3 style={{ color: "black" }}>One time chat</h3>
                </>
              ) : (
                <>
                  {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                      {isValidLink(decryptText(message.text, correctSecretCode)) ? (
                        <a href={formatURL(decryptText(message.text, correctSecretCode))} target='_blank' style={{ color: "white" }}>{decryptText(message.text, correctSecretCode)}</a>

                      ) : (
                        <>
                          {decryptText(message.text, correctSecretCode)}
                        </>
                      )}

                    </div>
                  ))}
                </>
              )}

            </div>
            <div className="chat-footer">
              <AutoGrowTextarea Onsubmit={handleSend} setMessage={setMessage} isOpen={isOpen} />
              {/* <input 
              type="text" 
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)} 
              placeholder="Type a message..." 
            />
            <button onClick={sendMessage}>Send</button> */}
              <div ref={scrollRef}></div>
            </div>

          </div>
        </div>
      )}

    </>
  );
}

export default ChatPopup;
