import React, { useState } from "react";
import "./SharePopup.css";
import SharePopup2 from "../../Pages/SharePopup2";

const SharePopup = ({calculate_question,users}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const shareToWhatsApp = () => {
    const url = encodeURIComponent(window.location.href);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${url}`;
    window.open(whatsappUrl, "_blank");
  };

  const closeModalShare = () => setIsPopupOpen(false);
  return (
    <div className="share-container">
      <button className="share-icon" onClick={togglePopup}>
      <i class="fa fa-share-alt" aria-hidden="true"></i>
      </button>
      <SharePopup2 isOpen={isPopupOpen} onRequestClose={closeModalShare} calculate_question={calculate_question} users={users}/>
      {/* {isPopupOpen && (
        <div className="sharepopup">
          <button className="sharepopup-option" onClick={shareToWhatsApp}>
            WhatsApp
          </button>
          <button className="sharepopup-option" onClick={copyToClipboard}>
            Copy
          </button>
        </div>
      )} */}
    </div>
  );
};

export default SharePopup;
