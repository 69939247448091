import { useState, useEffect } from "react";
import './ProgressBar.css'
export default function ProgressBar({calculate_question}) {
    // const totalQuestions = 7;
    // const correctAnswers = 7;
    const [percentage, setPercentage] = useState(0); // Start with 0 for animation effect
    useEffect(() => {
      // Delay the update to trigger animation
      setTimeout(() => {
        const calculatedPercentage = calculate_question;
        setPercentage(calculatedPercentage);
      }, 100); // Small delay to ensure animation runs
    }, [calculate_question]);
    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;
  return (
    <div className="App">
      <div className="progress-container">
        <svg width="80" height="80" className="svg">
          {/* Background Circle */}
          <circle cx="40" cy="40" r={radius} stroke="#ddd" strokeWidth="6" fill="none" />
          {/* Progress Circle */}
          <circle
            cx="40"
            cy="40"
            r={radius}
            stroke="red"
            strokeWidth="6"
            fill="none"
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            className="progress-ring__circle"
          />
        </svg>
        {/* Centered Percentage Text */}
        <span className="percentage-text">{Math.round(percentage.toFixed(1))}%</span>
      </div>
    </div>
  )
}
