import React ,{useState}from 'react'
import axios from 'axios'
export default function Feedback() {
  const[feedback,setfeedback]=useState('')
  const[email,setemail]=useState('')
  const[name,setname]=useState('')
  const[load,setLoad]=useState(false)
  const validateEmail = (email) => {
    let valid= String(email)
       .toLowerCase()
       .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
       );
       let valid_data= Array.isArray(valid)?true:false
       return valid_data
   };
  
  const handleSend=async()=>{
    try{
    setLoad(true)
  await axios.post(`${process.env.REACT_APP_SOCKET_URL}/send_feedback_mails`,{
    emails:[email],
    name:name,
    feedback:feedback
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
  }
  })
  setemail('')
  setfeedback('')
  setLoad(false)

}catch(err){

}
}
  return (
    <div class="container2">
    <div class="features">
    <h2>Feedback</h2>
    <p>At Friship, we are constantly striving to improve your experience. Your thoughts, ideas, and suggestions play a vital role in shaping our platform. Whether you’ve encountered an issue, have a feature request, or simply want to share your experience, we’d love to hear from you!</p>
    <h3>How to Share Feedback</h3>
    <ul>
        <li>Submit a Form: Use our in-app feedback form to quickly send your thoughts.</li>
        <li>Email Us: Drop us a line at <a href="mailto:frishipcom@gmail.com">frishipcom@gmail.com</a> for detailed feedback.</li>
        {/* <li>Connect on Social Media: Reach out to us on [Social Media Handles] for quick interactions.</li> */}
    </ul>
    <h3>What We’d Love to Hear</h3>
    <ul>
        <li>Suggestions for new features or improvements.</li>
        <li>Feedback on existing questions or gameplay.</li>
        <li>Issues or bugs you’ve encountered.</li>
        <li>Testimonials and stories about how Friship has brought you closer to your partner.</li>
    </ul>
    <h3>Our Commitment to You</h3>
    <p>We review every piece of feedback and prioritize updates based on your needs and suggestions. While we may not be able to implement every idea right away, your input helps us make Friship the best it can be.</p>
    <p>Thank you for helping us grow and improve! Together, we’re creating something truly special.</p>
{/* <div style={{display:"flex",flexDirection:"column"}}>
    <input style={{height:"27px",width:"100%",marginBottom:"11px",outline:"none"}} placeholder='Enter your email' value={email} onChange={(e)=>setemail(e.target.value)}/>
    <input style={{height:"27px",width:"100%",marginBottom:"11px",outline:"none"}} placeholder='Enter your name' value={name} onChange={(e)=>setname(e.target.value)}/>
    <textarea style={{width:"100%",marginBottom:"11px",border:"1px solid"}} placeholder='Send your query' value={feedback} onChange={(e)=>setfeedback(e.target.value)}/>
    {email.trim()!=='' && feedback.trim()!=='' && validateEmail(email)?(
  <div class="cta" style={{marginTop:"5px",paddingBottom:"10px"}} onClick={()=>handleSend()}>
  <button>{load?"Sending...":"Send"}</button>
  </div>
    ):''}
  
    </div> */}
</div>
<hr/>
<div class="cta" style={{marginTop:"0px"}} onClick={() => window.open('/', '_blank', 'noopener,noreferrer')}>
    <button>Let’s Play, Talk, and Connect</button>
</div>
</div>
  )
}
