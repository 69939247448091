import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import './Share.css'
import emaillogo from "./emaillogo.png"
import ProgressBar from '../Component/ProgressBar/ProgressBar';
import { GetScore } from '../AllApis/ApiIntegrate';
export default function ShareScreendetails() {
  const navigate = useNavigate()
  const { id } = useParams();
  const[ calculate_question,setCalculate_data] = useState(0)
  const[user1,setUser1]=useState('')
  const[user2,setUser2]=useState('')
  useEffect(()=>{
    const Getdata=async()=>{
      let res=await GetScore(id)
      if(res){
        
        setCalculate_data(Number(res.data.message.score))
        setUser1(res.data.message.user1)
        setUser2(res.data.message.user2)
      }
    }
    Getdata()
  },[id])

  
  return (
    <>
     <div style={{height:"100vh",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",color:"black"}}>
      <div class="anicard">
        <div class="share_img">
          <div class="share_logo">
            <img src={emaillogo} style={{width:"98px",marginTop:"10px"}}/>
          </div>
        </div>
        <div class="anicard-content">
          <img src='' />
          <h2>Start matching</h2>
          <div className="container_image">
          <ProgressBar calculate_question={calculate_question}/>
               
      {/* {calculate_question==='good' &&(
        <div className="emoji bouncing-image">❤️</div>
       )} 
      {calculate_question==='average' &&(
        <div className="emoji bouncing-image">😊</div>
       )} 
      {calculate_question==='bad' &&(
        <div className="emoji bouncing-image">😢</div>
       )}  */}
   
  </div>
  <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"11px"}}>
  <span class='power_off_text'> <span style={{fontStyle:"italic",color:"blue"}}>{user2}</span> and <span style={{fontStyle:"italic",color:"blue"}}>{user1}</span> playing this game. You can play with your partner too!</span>
  {/* <p className='power_off_text' style={{fontSize:"26px",marginLeft:"9px"}}><span style={{fontStyle:"italic",color:"blue"}}>{user2}</span> and <span style={{fontStyle:"italic",color:"blue"}}>{user1}</span> , playing this game. You can play with your partner too!</p> */}
  <button class="animated-button" style={{marginTop:"17px"}} onClick={()=>navigate('/?model_open=true')}>Start connection</button>
        </div>
        </div>
      </div>
      </div>
    </>
  )
}
