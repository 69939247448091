import React from 'react';
import './ButtonSection.css';
import { useNavigate } from 'react-router-dom';

export default function ButtonSection() {
  const navigate = useNavigate();

  return (
    <div className="footer-container">
      <div className="button-bar">
        <span className="footer-text">
          Friship © 2025
        </span>
        <nav className="menu">
          
          <button className="btn1" onClick={()=>window.open('/about', '_blank', 'noopener,noreferrer')}>
            About
          </button>
          <button
            className="btn1"
            onClick={() =>
              window.open('/privacy', '_blank', 'noopener,noreferrer')
            }
          >
            Security
          </button>
          <button
            className="btn1"
            onClick={() =>
              window.open('/feedback', '_blank', 'noopener,noreferrer')
            }
          >
            Feedback
          </button>
        </nav>
      </div>
    </div>
  );
}
