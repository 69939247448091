import React, { useState, useEffect, useRef } from 'react';
import './Profile.css'; // Create this CSS file
import { ProfilePicUpdate, UpdateUserDetails, UserProfilePic } from '../../AllApis/ApiIntegrate';
import { FaPen, FaUpload } from "react-icons/fa"
import Loder from '../LoderComponent/Loder'
import { useSelector } from 'react-redux';
import { isValidLink } from '../../Utiles';
const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState("");
  const [popupImageUrl2, setPopupImageUrl2] = useState("");
  const [hidden, setHidden] = useState(false)
  const [ProfileImage, setProfileImage] = useState(null)
  const [pofileuploadstatus, setpofileuploadstatus] = useState(false)
  const [name, setName] = useState('')
  const [quote, setQuote] = useState('')
  const [link, setLink] = useState('')
  let url_valid=isValidLink(link)
  console.log(url_valid,link,'url_valid');
  
  const ProfileImageRef = useRef()
  const userlogin = useSelector(state => state.myReducer.data)
  console.log(userlogin, 'userloginuserlogin');
  useEffect(() => {
    const Pic = async () => {
      let res = await UserProfilePic(userlogin.user_id)
      setPopupImageUrl(res.data.user_details.profile_img || `${window.location.origin}/default_profile.webp`)
      setQuote(res.data.user_details.quote)
      setLink(res.data.user_details.link)
    }
    if (userlogin?.user_id) {
      Pic()
      setName(userlogin.username)
    }
  }, [userlogin?.user_id, isModalOpen])

  //base64 convert 
  const MAX_FILE_SIZE = 6862851000000; // set your desired maximum file size in bytes

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const MAX_WIDTH = 800; // set your desired maximum width
        const MAX_HEIGHT = 600; // set your desired maximum height

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.7); // adjust the quality if needed
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const convertToBase64 = async (file) => {
    const resizedBlob = await resizeImage(file);
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(resizedBlob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    return base64;
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    // console.log(file.size, 'jjjjjjjjj',MAX_FILE_SIZE);
    if (file.size > MAX_FILE_SIZE) {
      // Handle the case where the file size is too large
      console.log('File size exceeds the maximum allowed size');
      return;
    }

    console.log(file.size, 'jjjjjjjjj');
    const base64 = await convertToBase64(file);
    setProfileImage(base64);

  };

  const ProfileImageupload = async () => {
    const json = {
      profile_img: ProfileImage,
      user_id: userlogin.user_id
    }
    try {
      setpofileuploadstatus(true)
      const response = await ProfilePicUpdate(json);

      if (response) {
        setpofileuploadstatus(false)

      }
    } catch (error) {
      console.error(error);
      setpofileuploadstatus(false)
    } finally {
      setPopupImageUrl(ProfileImage)
      // setPopupImageUrl(userlogin.user_pic)
      // dispatch(setData({...userlogin,user_pic:ProfileImage,user_pic_present:true}))
      // dispatch(setRefresh(new Date().getMilliseconds()))
      // setrefress(new Date().getMilliseconds())
    }
  }
  useEffect(() => {
    if (ProfileImage !== null) {
      ProfileImageupload()
    }
  }, [ProfileImage])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleClickOutside = (event) => {
    const modal = document.querySelector('.modal-content_profile');
    const trigger = document.querySelector('.modal-trigger_profile');

    if (isModalOpen && !modal.contains(event.target) && !trigger.contains(event.target)) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  const handleOpenPopup = (imageUrl) => {
    setPopupImageUrl(imageUrl);

  };

  const UpdateUser = async () => {
    if (link && !url_valid) return;
  
    const json = { quote, user_id: userlogin.user_id, name, link };
  
    try {
      const response = await UpdateUserDetails(json);
      if (response) setIsModalOpen(false);
    } catch (err) {}
  };

  return (
    <div className="modal-container_profile" style={{ color: "black" }}>
      {/* Trigger Image */}
      <img
        src={popupImageUrl} // Replace with your image path
        alt="Open modal"
        className="modal-trigger_profile"
        onClick={toggleModal}
      />
      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="modal-overlay_profile">
          <div className="modal-content_profile">
            <div className="modal-arrow_profile"></div>
            <h2>Profile</h2>
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileUpload(e)} name={ProfileImage} ref={ProfileImageRef} />
                {!hidden && (
                  <label for="imageUpload"><FaPen className="icon" style={{ padding: "8px", fontSize: "14px" }} /></label>
                )}
              </div>
              <div className="avatar-preview" onClick={() => handleOpenPopup(popupImageUrl)}>
                <div id="imagePreview" style={{ backgroundImage: `url(${popupImageUrl})` }}>
                  {pofileuploadstatus && (

                    <div style={{ padding: "57px" }}>
                      <Loder />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <img
        src="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg" // Replace with your image path
        alt="Open modal"
        className="model_profile_img"
        onClick={toggleModal}
      /> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <div className="form-group">
                  <span className="input-icon">
                    <i className="fa fa-user"></i>
                  </span>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                </div>
                <div className="form-group">
                  <span className="input-icon">
                    <i className="fa fa-quote-left"></i>
                  </span>
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Enter your quote"
                    value={quote}
                    onChange={(e) => setQuote(e.target.value)}
                  />

                </div>
                <div className="form-group">
                  <span className="input-icon">
                    <i className="fa fa-link"></i>
                  </span>
                  <input
                    type="link"
                    className="form-control"
                    placeholder="Enter your website"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                 
                </div>
                {link && !url_valid?(
                <span style={{color:"red"}}>url not valid</span>

                ):""}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button class="apple-button" onClick={() => UpdateUser()}>
                    <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 2L8 6H4V8H20V6H16L12 2Z" fill="currentColor" />
                      <path d="M4 10H20V20H4V10Z" fill="currentColor" />
                    </svg>
                    Save
                  </button>
                </div>
              </div>

            </div>
            {/* <p>This is your animated modal!</p> */}


          </div>
        </div>
      )}
    </div>
  );
};
export default Profile;