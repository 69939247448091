import React, { useState, useEffect } from "react";
import "./Welcome.css"; // Ensure this CSS is implemented correctly
import Connect from "./Connect";
import ButtonSection from "../MenuSection/ButtonSection";
import Login from "../LoginPage/Login";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
import AnimatedCard from "../AnimatedCard/AnimatedCard";

const Welcome = () => {
    const [index, setIndex] = useState(0); // Current sentence index
    const [wordIndex, setWordIndex] = useState(0); // Current word index
    const [words, setWords] = useState([]); // Words of the current sentence
    const sentences = [
        "First know then go."
      ];
    useEffect(() => {
      setWords(sentences[index].split(" "));
      setWordIndex(0); // Reset word index for the new sentence
    }, [index]);
  
    useEffect(() => {
      if (wordIndex < words.length) {
        const timeout = setTimeout(() => {
          setWordIndex((prev) => prev + 1);
        }, 400); // Delay between words
        return () => clearTimeout(timeout);
      } else {
        const nextSentenceTimeout = setTimeout(() => {
          setIndex((prev) => (prev + 1) % sentences.length); // Loop to the next sentence
        }, 2000); // Delay before the next sentence
        return () => clearTimeout(nextSentenceTimeout);
      }
    }, [wordIndex, words.length]);
  
    const [token, setToken2] = useState(localStorage.getItem('token'))
  return (
    <>
    <div className="text-container">
      
        {/* <img src="https://cdn.pixabay.com/photo/2017/01/08/21/37/flame-1964066_1280.png" alt="" width='80px'/> */}
        <div className="full_logo">
        <img src="logo.png" width='49px'/>
        <img src='logotext.png' width='59px'/>
        </div>
      <div className="sentence">
      {words.slice(0, wordIndex).map((word, idx) => (
        <span
          key={idx}
          className="word"
          style={{
            animation: "fadewelcomeIn 0.5s ease-in-out forwards",
            opacity: 0,
          }}
        >
          {word} 
        </span>
      ))}
    </div>
    <AnimatedCard 
      />
    <p style={{fontSize:"16px"}}> Exploring each other, with no third person involved.</p>
    <Connect/>
    
    <p style={{fontSize:"16px"}}>Privicy❌ One time convertion✅</p>

    </div>
    <ButtonSection/>
    <Login setToken2={setToken2}/>
    <CustomerSupport/>
    </>
  );
};

export default Welcome;
