import React from 'react';
import './BouncingImage.css'; // Import the CSS for animations
import { useNavigate } from 'react-router-dom';
import SharePopup from '../SharePopup/SharePopup';
import ProgressBar from '../ProgressBar/ProgressBar';

const BouncingImage = ({calculate_question,users}) => {
  const navigate=useNavigate()
  return (
    <>
<div style={{height:"100vh",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",color:"black"}}>
      <div class="anicard">
        <div class="share_img" style={{marginTop:"8px",justifyContent:"end",marginRight:"9px"}}>
          {/* <div class="share_logo">
            <img src={emaillogo} style={{width:"98px",marginTop:"10px"}}/>
          </div> */}
     <SharePopup calculate_question={calculate_question} users={users} />

        </div>
        <div class="anicard-content">
          <img src='' />
          <h2>Matching</h2>
          <div className="container_image">
          <ProgressBar  calculate_question={calculate_question}/>
      
      {/* {calculate_question==='good' &&(
        <div className="emoji bouncing-image">❤️</div>
       )} 
      {calculate_question==='average' &&(
        <div className="emoji bouncing-image">😊</div>
       )} 
      {calculate_question==='bad' &&(
        <div className="emoji bouncing-image">😢</div>
       )}  */}
   
  </div>
  <div style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"11px"}}>
  <span class='power_off_text'> <span style={{fontStyle:"italic",color:"blue"}}>{users[0]?.username}</span> and <span style={{fontStyle:"italic",color:"blue"}}>{users[1]?.username}</span> playing this game. You can play with your partner too!</span>
  {/* <p style={{fontSize:"26px",marginLeft:"9px"}}><span style={{fontStyle:"italic",color:"blue"}}>{users[0]?.username}</span> and <span style={{fontStyle:"italic",color:"blue"}}>{users[1]?.username}</span> , thank you all for playing this!</p> */}
  <button class="animated-button" style={{marginTop:"17px"}} onClick={()=>navigate('/?model_open=true')}>Start connection</button>
        </div>
        </div>
    </div>
    {/* <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"9px"}}>
     <SharePopup calculate_question={calculate_question} users={users} />
     </div> */}
    </div>
    
     
    </>
  
  );
};

export default BouncingImage;
