import React from 'react'

export default function Privacy() {
  return (
    <div class="container2">
    <h1>Welcome to Friship</h1>
    <p>A unique platform designed to bring partners closer through fun, thought-provoking, and meaningful conversations. We believe that connection thrives on understanding, and our software is here to spark those "aha" moments in your relationship.</p>
    <p>Whether you're exploring each other’s personalities, revisiting shared memories, or diving into deep topics you’ve never touched before, Friship is the perfect companion for strengthening your bond.</p>
  
    <div class="privacy">
        <h2>Security and Privacy</h2>
        <p>At Friship, your privacy and security are our top priorities. We are committed to protecting your data and ensuring you have a safe experience while using our platform.</p>
        <h3>What We Save</h3>
        <ul>
            <li>Login Information: We securely store only the necessary information to manage your account, such as your email address and username.</li>
            <li>Questions: To improve your experience, we save the questions and prompts available in the app.</li>
        </ul>
        <h3>What We Don’t Save</h3>
        <ul>
            <li>User Messages: All conversations and answers shared within the app are temporary and never stored on our servers. Your interactions remain private and exclusive to you and your partner.</li>
        </ul>
        <h3>Data Protection Measures</h3>
        <ul>
            <li>We use advanced encryption protocols to safeguard your data.</li>
            <li>Regular audits and security updates ensure your information is always protected.</li>
        </ul>
        <p>Your trust means everything to us, and we are committed to maintaining a secure and private environment for all our users. If you have any questions or concerns, please feel free to <a href="mailto:frishipcom@gmail.com">frishipcom@gmail.com</a>.</p>
        <p style={{fontSize:"16px"}}>Privicy❌ One time convertion✅</p>
   
    </div>
    <div class="cta" onClick={() => window.open('/', '_blank', 'noopener,noreferrer')}>
        <button>Let’s Play, Talk, and Connect</button>
    </div>
</div>
  )
}
