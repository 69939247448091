import React, { useState } from 'react';
import Loader from "../Loader/Loader"
import CrossIcon from "./cross.png"
const AcceptRequest = ({ users }) => {
    const [modalVisible, setModalVisible] = useState(true);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="modal-box">
                        {/* Button to trigger the modal */}
                        {/* <button
              type="button"
              className="show-modal"
              onClick={toggleModal}
            >
              Login Form
            </button> */}
                        {/* <BottomRight toggleModal={toggleModal}/> */}
                        {/* Modal */}
                        {modalVisible && (
                            <div className="modal fade show" style={{ display: 'block' }} role="dialog">
                                <div className="modal-content">

                                    <div className="modal-body" style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                                        <h3 className="title">{users == 2 ?'Only two users are joining':'Waiting for you partner'}</h3>
                                        {users == 2 ? (
                                            <img src={CrossIcon} width="85px" />
                                        ) : (
                                            <Loader />
                                        )}


                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AcceptRequest;
