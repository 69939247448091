import React,{useEffect,useState} from "react";
import ReactCardSlider from "react-card-slider-component";
import './AnimatedCard.css'
import ReactCardCarousel from "react-card-carousel"
const AnimatedCard = ({ title, description, image }) => {
  const slides = [
    {
      image: "https://picsum.photos/200/300",
      title: "This is a title 1",
      description: "This is a description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/600/500",
      title: "This is a second title 2",
      description: "This is a second description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 3",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 4",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 5",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 6",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 7",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 8",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 9",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 11",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 12",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 13",
      description: "This is a third description",
      clickEvent: "sliderClick"
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title 14",
      description: "This is a third description",
      clickEvent: "sliderClick"
    }
  ];
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  const CONTAINER_STYLE = {
    position: "relative",
    // height: "100vh",
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: isMobile?'263px':'354px',
    zIndex:0
    
  };
  const CARD_STYLE = {
    height: isMobile?'250px':"350px",
    width:isMobile?'200px': "300px",
    padding: "20px",
    textAlign: "center",
    background: "#0078ff",
    color: "#FFF",
    fontFamily: "sans-serif",
    fontSize: "14px",
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  const IMAGE_STYLE = {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "10px",
  };
  const USERNAME_STYLE = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  };
  let data=[
    {code:"💖 Our love is like a secret language—unspoken yet understood, invisible yet felt, simple yet infinite.",name:'Alice'},
    {code:"💌 No matter where life takes us, my heart will always find its way back to you. You are my home, my forever.",name:"Shana"},
    {code:"✨ Even in silence, our hearts compose the sweetest melody. Every beat whispers your name, every moment sings our love.",name:"Charlie"},
    {code:"❤️ You are the reason my heart smiles, my soul shines, and my world feels complete. With you, love isn’t just a word—it’s my reality.",name:"David"},
    {code:"🌙 If love were a star, ours would be the brightest in the sky, guiding us through every dark night and lighting up every beautiful day.",name:"Bob"}
  ]
  return (
    <>
      {/* <ReactCardSlider slides={slides} /> */}
      <div style={CONTAINER_STYLE} >
      <ReactCardCarousel autoplay={true} autoplay_speed={2500} >
        {data.map((name, index) => (
          <div key={index} style={CARD_STYLE}>
            <img
              src={`https://i.pravatar.cc/150?img=${index + 9}`}
              alt="Profile"
              style={IMAGE_STYLE}
            />
            <div style={USERNAME_STYLE}>{name.name}</div>
            <p>
            {name.code}
            </p>
          </div>
        ))}
      </ReactCardCarousel>
    </div>
    
    </>
    // <div className="anicard">
    //   <img src={image} alt={title} className="card-image" />
    //   <div className="anicard-content">
    //     <h3 className="anicard-title">{title}</h3>
    //     <p className="anicard-description">{description}</p>
    //   </div>
    // </div>
  );
};

export default AnimatedCard;
