import React, { useState, useEffect } from 'react';
import './Login.css'; // For custom styles
import BottomRight from '../BottomRight';
import Question from '../QuestionCreateScreen/QuestionCreate';
import { CheckOtp, forgetPassword, resetPassword, userLogin, userRegister } from '../../AllApis/ApiIntegrate';
import InformationIcon from "./Information.png"
import { validateEmail } from '../../Utiles'
import Loader from '../Loader/Loader';
import InputOtp from '../../OTPInput/InputOtp'
import { useDispatch } from 'react-redux';
import { setData } from '../../redux/action/LoginAction';
const Login = ({ setToken2 }) => {
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false);
  const [loginshow, setLoginshow] = useState(true)
  const [token, setToken] = useState(localStorage.getItem('token'))

  const [name, setName] = useState('')
  const [dob, setDob] = useState("1997-01-01")
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show_password, setShow_Password] = useState(false)
  const [valid_user, setValid_user] = useState('')


  const json = JSON.stringify({
    email,
    password,
    dob: dob,
    name,
  })
  const [loader, setLoder] = useState(false)
  const [validation, setValidation] = useState(false)
  const [valid_message, setValid_Message] = useState('')
  const [valid_message1, setValid_Message1] = useState('')
  const [valid_message2, setValid_Message2] = useState('')
  const [api_validation, setApi_validation] = useState('')
  const [loding_show, setLanding_show] = useState(false)
  const handleSubmit = async (e) => {
    if (!email) {
      setValidation(true)
      setValid_Message1('This field is required')
    } if (email) {
      if (!validateEmail(email)) {
        setValidation(true)
        setValid_Message1('Email format not valid')
      }
    } if (!password) {
      setValidation(true)
      setValid_Message('This field is required')
    } if (!name) {
      setValidation(true)
      setValid_Message('This field is required')
    } if (dob.length < 9) {
      setValidation(true)
      setValid_Message2('This field is required')
    }
    if (email && validateEmail(email) && password && name) {
      setLoder(true)
      try {

        let response = await userRegister(json)
        if (response) {
          setLoder(false)
          const token = response.data.token;
          // dispatch(setData(response.data.user_id))
          dispatch(setData({ user_id: response.data.user_id, username: response.data.username }))

          localStorage.setItem('token', token);
          setToken(token)
          setToken2(token)
          //  navigate("/")
          // dispatch(setData(response.data.user_id))
          //  localStorage.setItem("user_id",response.data.user_id)

          //  setLogin_true(true)
          //  setIsLoggedIn(true)
        }
      } catch (err) {
        setLoder(false)
        setApi_validation('Email already exist ')

      }
    }
  }
  const json2 = JSON.stringify({
    email,
    password,
  })
  const handleSubmit2 = async (e) => {
    if (!email) {
      setValidation(true)
      setValid_Message1('This field is required')
    } if (email) {
      if (!validateEmail(email)) {
        setValidation(true)
        setValid_Message1('Email format not valid')
      }
    } if (!password) {
      setValidation(true)
      setValid_Message('This field is required')
    } if (email && validateEmail(email) && password) {
      setValidation(false)
      setLoder(true)
      try {
        let response = await userLogin(json2)
        if (response) {
          setLoder(false)
          const token = response.data.token;
          setToken(token)
          dispatch(setData({ user_id: response.data.user_id, username: response.data.username }))
          //  localStorage.setItem("user_id",response.data.user_id)
          localStorage.setItem('token', token);
          //  setLogin_true(true)
          //  setIsLoggedIn(true)
        }
      } catch (err) {
        setValidation(true)
        setValid_user('Invalid email or password')
        setLoder(false)

      }
    }
  }

  //  console.log(dob.length,"dob.length");

  useEffect(() => {
    if (validation) {
      if (!email) {
        setValidation(true)
        setValid_Message1('This field is required')
      } if (email) {
        setApi_validation('')
        if (!validateEmail(email)) {
          setValidation(true)
          setValid_Message1('Email format not valid')
        }
      } if (!password) {
        setValidation(true)
        setValid_Message('This field is required')
      } if (!name) {
        setValid_Message('This field is required')
      } if (dob.length < 9) {
        setValidation(true)
        setValid_Message('This field is required')
      }

    }
  }, [validation, email, password, name, dob])
  const [forget_password, setForgetPassword] = useState(true)
  const [otp, setOtp] = useState("")
  const [err, setErr] = useState("")
  const [otpview, setOtpview] = useState(false)
  const handleOtpChange = (e) => {
    setOtp(e)
  }
  const json3 = JSON.stringify({
    email,
  })
  console.log(email, "NNNN");

  const handleForgetPassword = async (e) => {
    setLoder(true)

    try {
      if (!email) {
        setValidation(true)
        setValid_Message1('This field is required')
      } if (email) {
        if (!validateEmail(email)) {
          setValidation(true)
          setValid_Message1('Email format not valid')
        }
      } if (email && validateEmail(email)) {
        let response = await forgetPassword(json3)
        if (response) {
          setOtpview(true)
          setLoder(false)
        }
      }

    } catch (err) {
      setValidation(true)
      // setOtpview(true)
      setValid_Message1('User not found')
    }
  }

  const [otpErr, setOtpErr] = useState('');
  const [nextpassword, setNextPassword] = useState(false)
  useEffect(() => {
    //   const json2 = JSON.stringify({ email, otp });

    const checkOtpData = async () => {
      try {
        let res = await CheckOtp(email, otp);
        setOtpErr(res.data.status);
        setNextPassword(true)
      } catch (err) {

        setOtpErr(true);
      }
    };


    let timeoutId;


    timeoutId = setTimeout(() => {
      if (email && otp) {
        checkOtpData();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);

  }, [email, otp]);


  const handleResetPassword = () => {
    setNextPassword(true)
  }

  const handleSubmit3 = async (e) => {
    setLoder(true)

    const json2 = JSON.stringify({
      email, otp, newPassword: password
    })
    try {
      if (!password) {
        setValidation(true)
        setValid_Message('This field is required')
      } if (password) {
        let response = await resetPassword(json2)
        if (response) {
          setLoder(false)
          const token = response.data.token;
          setToken(token)
          // dispatch(setData(response.data.user_id))
          //  localStorage.setItem("user_id",response.data.user_id)
          localStorage.setItem('token', token);
          //  setLogin_true(true)
          //  setIsLoggedIn(true)
        }
      }
    } catch (err) {
      setLoder(false)

    }
  }
  const toggleModal = () => {
    setModalVisible(!modalVisible);
    setOtpview(false)
    setForgetPassword(true)
    setLoginshow(true)
    setValidation(false)
    setValid_Message('')
    setNextPassword(false)
    setEmail('')
    setPassword('')
    setValid_user('')
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="modal-box">
            {/* Button to trigger the modal */}
            {/* <button
              type="button"
              className="show-modal"
              onClick={toggleModal}
            >
              Login Form
            </button> */}
            <BottomRight toggleModal={toggleModal} />
            {/* Modal */}
            {modalVisible && (
              <div className="modal2 fade show" style={{ display: 'block', zIndex: 1000 }} role="dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    onClick={toggleModal}
                    aria-label="Close"
                  >
                    ×
                  </button>

                  {token ? (<Question setModalVisible={setModalVisible} />) : (
                    <>
                      {loginshow ? (
                        <div className="modal-body">
                          {forget_password ? (
                            <>
                              <h3 className="title">Login Form</h3>
                              <p className="description">Login here using Email & Password</p>
                            </>
                          ) : (
                            <h3 className="title">Forget Password</h3>
                          )}

                          {!otpview && (
                            <>
                              <div className="form-group">
                                <span className="input-icon">
                                  <i className="fa fa-envelope"></i>
                                </span>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  value={email}
                                  onChange={(e) => { setValid_user(''); setEmail(e.target.value) }}
                                />
                              </div>
                              {validation && <>
                                {!email || !validateEmail(email) ? (
                                  <>
                                    <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                    <span style={{ color: "red" }}>{valid_message1}</span>
                                  </>
                                ) : null}
                              </>
                              }
                            </>
                          )}




                          {otpview && <>

                            {!nextpassword && <InputOtp length={6} onChange={handleOtpChange} />}
                            {nextpassword && <>
                              <div className="form-group">
                                <span className="input-icon">
                                  <i className="fa fa-key"></i>
                                </span>
                                <input
                                  type={show_password ? "" : 'password'}
                                  className="form-control"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => { setValid_user(''); setPassword(e.target.value) }}
                                />
                                <span className="input-icon_eye" onClick={() => setShow_Password(!show_password)}>
                                  <i className={show_password ? "fa fa-eye" : "fa fa-eye-slash"}></i>

                                </span>
                              </div>
                              {validation && <>
                                {!password && (
                                  <>
                                    <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                    <span style={{ color: "red" }}>{valid_message}</span>
                                  </>
                                )}

                              </>}
                            </>}
                            {otpErr && (
                              <div style={{ marginTop: "9px" }}>
                                <span style={{ color: "red", marginTop: "6px" }}>Invalid OTP Code</span>
                              </div>
                            )}
                            {!nextpassword && (
                              <div>
                                <a className="forgot-pass" onClick={() => handleForgetPassword()}>
                                  Resend OTP
                                </a>
                              </div>

                            )}

                            {nextpassword && (
                              <>
                                <button className="btn" style={{ marginTop: "20px" }} onClick={() => handleSubmit3()}>Reset new password</button>

                              </>
                            )}
                          </>}


                          {forget_password && (
                            <>
                              <div className="form-group">
                                <span className="input-icon">
                                  <i className="fa fa-key"></i>
                                </span>
                                <input
                                  type={show_password ? "" : 'password'}
                                  className="form-control"
                                  placeholder="Password"
                                  value={password}
                                  // onChange={(e) => setPassword(e.target.value)}
                                  onChange={(e) => { setValid_user(''); setPassword(e.target.value) }}
                                />
                                <span className="input-icon_eye" onClick={() => setShow_Password(!show_password)}>
                                  <i className={show_password ? "fa fa-eye" : "fa fa-eye-slash"}></i>

                                </span>
                              </div>
                              {valid_user && (<span style={{ color: "red" }}>{valid_user}</span>)}

                              {validation && <>
                                {!password && (
                                  <>
                                    <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                    <span style={{ color: "red" }}>{valid_message}</span>
                                  </>
                                )}

                              </>}
                            </>
                          )}


                          {!forget_password && !otpview ? (
                            <>

                              {loader ? (
                                <Loader show='false' />

                              ) : (
                                <button className="btn" style={{ marginTop: "20px" }} onClick={() => handleForgetPassword()}>Send OTP</button>

                              )}
                            </>
                          ) : ""}


                          {forget_password && (
                            <div>
                              {!loader && (
                                <>
                                  <a className="forgot-pass" onClick={() => setForgetPassword(!forget_password)}>
                                    Forgot Password?
                                  </a>
                                  <a className="forgot-pass" onClick={() => { setValidation(false); setLoginshow(false) }} style={{ marginLeft: "3px" }}>
                                    Sign Up
                                  </a>
                                </>
                              )}

                              {loader ? (
                                <Loader show='false' />

                              ) : (
                                <button className="btn" onClick={() => handleSubmit2()} style={{ marginTop: "20px" }}>Login</button>

                              )}
                            </div>
                          )}


                        </div>
                      ) : (
                        //register
                        <div className="modal-body">
                          <h3 className="title">Sign Up</h3>
                          <p style={{ color: 'black' }}>Name</p>
                          <div className="form-group">
                            <span className="input-icon">
                              <i className="fa fa-user"></i>
                            </span>
                            <input
                              type="name"
                              className="form-control"
                              placeholder="Enter your name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />

                          </div>
                          {validation && <>
                            {!name ? (
                              <>
                                <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                <span style={{ color: "red" }}>{valid_message}</span>
                              </>
                            ) : null}
                          </>}
                          <p style={{ color: 'black' }}>Date of birth</p>
                          <div className="form-group">

                            <span className="input-icon">
                              <i className="fa fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Enter your date of birth"
                              // value={dob}
                              onChange={(e) => setDob(e.target.value)}
                              max={new Date().toISOString().split('T')[0]}
                              value={dob}
                            />
                          </div>
                          {validation && <>
                            {!dob && (
                              <>
                                <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                <span style={{ color: "red" }}>{valid_message}</span>
                              </>
                            )}

                          </>}
                          <p style={{ color: 'black' }}>Email</p>
                          <div className="form-group">
                            <span className="input-icon">
                              <i className="fa fa-envelope"></i>
                            </span>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          {validation && <>
                            {!email || !validateEmail(email) ? (
                              <>
                                <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                <span style={{ color: "red" }}>{valid_message1}</span>
                              </>
                            ) : null}

                          </>}
                          <p style={{ color: 'black' }}>Password</p>
                          <div className="form-group">
                            <span className="input-icon">
                              <i className="fa fa-key"></i>
                            </span>
                            <input
                              type={show_password ? "" : 'password'}
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              // onChange={(e) => setPassword(e.target.value)}
                              onChange={(e) => { setValid_user(''); setPassword(e.target.value) }}
                            />
                            <span className="input-icon_eye" onClick={() => setShow_Password(!show_password)}>
                              <i className={show_password ? "fa fa-eye" : "fa fa-eye-slash"}></i>

                            </span>

                          </div>
                          <p style={{ color: "black" }}>
                            By clicking Sign Up, you agree to our  <a style={{ color: "blue", cursor: "pointer" }} onClick={() =>
                              window.open('/privacy', '_blank', 'noopener,noreferrer')
                            }>Terms & Privacy</a>.</p>
                          {validation && <>
                            {!password && (
                              <>
                                <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                                <span style={{ color: "red" }}>{valid_message}</span>
                              </>
                            )}

                          </>}
                          {loader ? (
                            <Loader show='false' />

                          ) : (
                            <button className="btn" onClick={() => handleSubmit()} style={{ marginTop: "20px" }}>Sign Up</button>

                          )}

                          <a className="forgot-pass" onClick={() => setLoginshow(true)}>
                            {'Back to Login >>'}
                          </a>
                        </div>
                      )}


                    </>
                  )}

                </div>

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
