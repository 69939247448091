import React, { useState, useEffect, useRef } from 'react';
import './OtherProfile.css';
import { UserProfilePic } from '../../AllApis/ApiIntegrate';
import { formatURL } from '../../Utiles';

const OtherProfile = ({ users, user_id_by_database, user_name,visible_screen2,isModalOpen_profile ,setIsModalOpen_profile}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState("");
  const [quote, setQuote] = useState('')
  const [link, setLink] = useState('')
  const ProfileImageRef = useRef()
  useEffect(()=>{
    if(isModalOpen_profile){
      setIsModalOpen(isModalOpen_profile)
      setIsModalOpen_profile(isModalOpen_profile)
    }
  },[isModalOpen_profile])
  // const userlogin = useSelector(state => state.myReducer.data)
  // useEffect(() => {
  //   if (userlogin) {
  //     setPopupImageUrl2(userlogin.user_pic)
  //   }
  // }, [userlogin])

  useEffect(() => {
    const Pic = async () => {
      let res = await UserProfilePic(user_id_by_database)
      setPopupImageUrl(res.data.user_details.profile_img || `${window.location.origin}/default_profile.webp`)
      setQuote(res.data.user_details.quote || 'Love is blind, but don’t trust blindly.')
      setLink(res.data.user_details.link)
    }
    if (user_id_by_database) {
      Pic()
      // setName(users[1]?.username)
    }
  }, [users?.length, isModalOpen])


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleClickOutside = (event) => {
    const modal = document.querySelector('.modal-content_profile2');
    const trigger = document.querySelector(visible_screen2?'.modal-trigger_profile23':".modal-trigger_profile2");

    if (isModalOpen && !modal?.contains(event.target) && !trigger?.contains(event.target)) {
      setIsModalOpen(false);
      setIsModalOpen_profile(false)
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  const handleOpenPopup = (imageUrl) => {
    setPopupImageUrl(imageUrl);

  };
  return (
    <>
      {popupImageUrl && (
        <div className="modal-container_profile2" style={{ color: "black" }}>

          {/* <img
            src={popupImageUrl}
            alt="Open modal"
            className={visible_screen2?'modal-trigger_profile23':"modal-trigger_profile2"}
            onClick={toggleModal}
            title='view profile'
          /> */}

          {isModalOpen && (
            <div className="modal-overlay_profile2">
              <div className="modal-content_profile2">
                <div className="modal-arrow_profile2"></div>
                <h2>Profile</h2>
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    {/* <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"  ref={ProfileImageRef} /> */}

                  </div>
                  <div className="avatar-preview">
                    <div id="imagePreview" style={{ backgroundImage: `url(${popupImageUrl})` }}>

                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div class="profile_card">
                    <h2>{user_name} quote</h2>
                    <p> {quote}</p>
                  </div>

                </div>
                {link && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div class="cta" style={{ backgroundColor: "#0096FF",borderRadius:"13px" }} onClick={() => window.open(`${formatURL(link)}`, '_blank', 'noopener,noreferrer')}>
                      <button style={{ backgroundColor: "#0096FF",borderRadius:"13px" }}>Visit my site</button>
                    </div>
                  </div>
                )}




              </div>
            </div>
          )}
        </div>
      )}

    </>
  );
};
export default OtherProfile;