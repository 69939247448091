import React,{useEffect, useState,useRef} from "react";
import "./Emoji.css";
import io from 'socket.io-client';
import { useParams } from "react-router-dom";
// const socket = io(process.env.REACT_APP_SOCKET_URL);
function Emoji({reff_emoji}) {
  const { id } = useParams();
  const socket = useRef();
  const queryParams = new URLSearchParams(window.location.search);
  const user_create_id = queryParams.get("user_create_id")||'';
  const[emoji,setEmoji]=useState('')
  useEffect(()=>{
    if(reff_emoji){
      setEmoji('')
    }
  },[reff_emoji])
  // Function to handle emoji click
  const handleEmojiClick = (event = null, emoji) => {
    const container = document.querySelector(".emoji-container");

    // Create a new emoji element
    const floatingEmoji = document.createElement("div");
    floatingEmoji.classList.add("animated-emoji");
    floatingEmoji.textContent = emoji;

    // Set the position of the emoji
    if (event) {
      // When triggered by a click
      const rect = event.target.getBoundingClientRect();
      floatingEmoji.style.left = `${rect.left + rect.width / 2}px`;
      floatingEmoji.style.top = `${rect.top + rect.height / 2}px`;
    } else {
      // When triggered automatically
      floatingEmoji.style.left = `${Math.random() * window.innerWidth}px`;
      floatingEmoji.style.top = `${window.innerHeight}px`;
    }

    floatingEmoji.style.fontSize = `${Math.random() * 1.5 + 1.5}rem`;

    // Add the emoji to the container
    container.appendChild(floatingEmoji);

    // Remove emoji after animation ends
    floatingEmoji.addEventListener("animationend", () => {
      floatingEmoji.remove();
    });
  };
 
  useEffect(() => {
    if(emoji){
    const interval = setInterval(() => {
      const emojis = [emoji];
      const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
      handleEmojiClick(null, randomEmoji);
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }
  }, [emoji]);

  const handleEmojiSend=(emoji)=>{
    socket.current.emit('sendEmojiToRoom', id,emoji,user_create_id || '')
  }
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current.on('receiveEmoji', (room_id,emoji,user_id) => {
      console.log(room_id,emoji,'MMM');
      
      if (room_id === id) {
        if (user_id !== user_create_id &&emoji) {
          setEmoji(emoji)
          // handleEmojiClick(emoji)
          
        }

      }

    });
  }, []);
console.log(emoji,'this is emoji');

  return (
    <div className="App" style={{userSelect:"none"}}>
      <div className="emoji-picker">
        {["👍", "❤️", "😂", "😮", "😢", "😡"].map((emoji, index) => (
          <button
            key={index}
            className="emoji"
            onClick={(event) => {
              handleEmojiClick(event, emoji);
              handleEmojiSend(emoji);
            }}
          >
            {emoji}
          </button>
        ))}
      </div>
      <div className="emoji-container"></div>
    </div>
  );
}

export default Emoji;
