import React ,{useEffect, useState,useRef}from 'react'
import Question from '../Component/QuestionScreen/Question'
import Emoji from '../Component/EmojiScreen/Emoji'
import Login from '../Component/LoginPage/Login'
import ChatPopup from '../Component/ChatScreen/ChatPopup'
import { useNavigate, useParams } from "react-router-dom";
import io from 'socket.io-client';
import TosterMessage from '../Component/TosterMessage/TosterMessage'
import UrlOpenScreen from '../Component/ShareUrlOpenScreen/UrlOpenScreen'
import AcceptRequest from '../Component/AcceptRequestModel/AcceptRequest'
import Profile from '../Component/Profile/Profile'
import OtherProfile from '../Component/Profile/OtherProfile'
// const socket = io(process.env.REACT_APP_SOCKET_URL);
export default function ShareScreen({appverify}) {
  const { id } = useParams();
   const socket = useRef();
   const navigate=useNavigate()
  const queryParams = new URLSearchParams(window.location.search);
  const url_share_or_not = queryParams.get("share");
  const owner_user_id = queryParams.get("owner_user_id");
  const user_name_query = queryParams.get("user_name");
  const[share_screen,setShare_screen]=useState(url_share_or_not)
  const [multiple_user,setMultiple_user]=useState(false)
 const[users,setUsers]=useState([])
 const[user_name,setUser_name]=useState('')
useEffect(()=>{
  if(!id){
    navigate('/')
  }
},[id])
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current.on('roomUser2', (msg) => {
      setUsers(msg)
        console.log(msg,"receive2");
        
    });
}, []);
useEffect(() => {
  const handleReload = (event) => {
    // Prevent the default behavior of the event
    event.preventDefault();

    // Optionally, you can display a message to the user
    const confirmationMessage = 'Reloading is disabled for this page.';
    event.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  // Add event listeners to disable reload
  window.addEventListener('beforeunload', handleReload);
  window.addEventListener('unload', handleReload);

  // Clean up event listeners on component unmount
  return () => {
    window.removeEventListener('beforeunload', handleReload);
    window.removeEventListener('unload', handleReload);
  };
}, []);

useEffect(() => {
  document.body.style.display = "flex";
  return () => {
    document.body.style.display = ""; 
  };
}, []);
const[reff_emoji,setReff_Emoji]=useState('')
const[emoji_show,setEmoji_show]=useState(true)


useEffect(()=>{
  // socket.emit('register', user_name, id); // Register the user
    socket.current.emit('joinRoom', id);

},[])
let user_id=localStorage.getItem("user_id")
console.log(user_id,users,'usersusersusers');
//  useEffect(()=>{
//   if(users.length===2){
//   setMultiple_user(true)
//  }
//  },[users.length])
  {/* {!user_name_query||!users[0]?.user_id?( */}
   const [token, setToken2] = useState(localStorage.getItem('token'))
  useEffect(()=>{
    if(appverify){
      setToken2('')
    }
  },[appverify])
   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768);
     };
 
     window.addEventListener("resize", handleResize);
 
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

   const[visible_screen2,setVisible_Screen2]=useState(false)
   const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div style={{marginTop:"-66px"}}>
     {!isMobile&& <h1 style={{top:"8%",position:"fixed",right:"29%"}}>Play and chat with your partner in a one-time conversation.</h1>}
      {user_name_query ?(
        <>
         {share_screen==='true'?(
        <UrlOpenScreen setShare_screen={setShare_screen} setUser_name={setUser_name} users={users} room_id={id} setMultiple_user={setMultiple_user}/>
      ):(
        <>
        <TosterMessage users={users} user_name={user_name} room_id={id}/>
        <Question user_name={user_name} users={users} setReff_Emoji={setReff_Emoji} setEmoji_show={setEmoji_show} owner_user_id={owner_user_id} setVisible_Screen2={setVisible_Screen2} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} user_id_by_database={users[1]?.user_id_by_database} view_pic={(Array.isArray(users)&& users.length>1 &&users[1]?.user_id_by_database)}/>
        {emoji_show && <Emoji reff_emoji={reff_emoji}/> } 
         {/* <Login setToken2={setToken2}/> */}
         <ChatPopup/> 
         {token &&  <Profile/>}
        {/* {(Array.isArray(users)&& users.length>1 &&users[1]?.user_id_by_database)&&<OtherProfile users={users}/>} */}
        {(Array.isArray(users)&& users.length>1 &&users[1]?.user_id_by_database)&&<OtherProfile  user_id_by_database={users[1]?.user_id_by_database} user_name={users[1]?.username} visible_screen2={visible_screen2} isModalOpen_profile={isModalOpen} setIsModalOpen_profile={setIsModalOpen}/>}
         
         </>
      )}
        </>
      ):(
        <>
         {!users[0]?.user_id ?(
    
    <AcceptRequest users={users.length}/>
  ):(
    <>
      {share_screen==='true'?(
    <UrlOpenScreen setShare_screen={setShare_screen} setUser_name={setUser_name} users={users} room_id={id} setMultiple_user={setMultiple_user}/>
  ):(
    <>
    <TosterMessage users={users} user_name={user_name} room_id={id}/>
    <Question user_name={user_name} users={users} setReff_Emoji={setReff_Emoji} setEmoji_show={setEmoji_show} owner_user_id={owner_user_id} setVisible_Screen2={setVisible_Screen2}setIsModalOpen={setIsModalOpen}  isModalOpen={isModalOpen} user_id_by_database={users[0]?.user_id_by_database} view_pic={(Array.isArray(users)&& users.length>1 &&users[0]?.user_id_by_database)}/>
    {emoji_show && <Emoji reff_emoji={reff_emoji}/> } 
     {/* <Login setToken2={setToken2}/> */}
     <ChatPopup/> 
     {token &&  <Profile/>}
     {(Array.isArray(users)&& users.length>1 &&users[0]?.user_id_by_database)&&<OtherProfile  user_id_by_database={users[0]?.user_id_by_database} user_name={users[0]?.username} visible_screen2={visible_screen2}  isModalOpen_profile={isModalOpen} setIsModalOpen_profile={setIsModalOpen}/>}

     </>
  )}
    </>
   )} 
        </>
      )}
     
      
      {multiple_user &&(
        <AcceptRequest users={users.length}/>
      )}
    
     
    </div>
  )
}
