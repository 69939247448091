import React, { useEffect, useState,useRef } from 'react';
import { FaCopy } from "react-icons/fa"
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { encryptText, generateHybridUniqueId } from '../../Utiles';
import { useSelector } from 'react-redux';
// const socket = io(process.env.REACT_APP_SOCKET_URL);
const Connect = () => {
  const socket = useRef();
  const queryParams = new URLSearchParams(window.location.search);
  const model_open = queryParams.get("model_open") ;
  console.log(model_open,"MRRIRI");
  
  const owner_user_id = useSelector(state => state.myReducer.data)
  const username = useSelector(state => state.myReducer.data)
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false);
  const [user_name, setuserName] = useState('');
  const [next, setNext] = useState(false)
  const [copy, setCopy] = useState(false)
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const handleNext = () => {
    if (user_name)
      setNext(true)
  }
useEffect(()=>{
  if(model_open==='true'){
    setModalVisible(!modalVisible);
  }
},[model_open])

  const uniqe_id = generateHybridUniqueId()
  const user_create_id = generateHybridUniqueId()
  const [connect_id, setConnect_id] = useState(uniqe_id)
  const [user_id, setUser_id] = useState(user_create_id)
  let user_id_by_database=owner_user_id.user_id||''

  const handleNext2 = () => {
    if (user_name && connect_id) {
      socket.current = io(process.env.REACT_APP_SOCKET_URL);
      socket.current.emit('register', user_name, connect_id,user_id,user_id_by_database); // Register the user
      socket.current.emit('joinRoom', connect_id); // Join the room
      localStorage.setItem("user_id", user_id);
      navigate(`/share/${connect_id}?user_create_id=${user_id}&user_name=${user_name}`)
      // Navigate to Chat component
    } else {
      alert('Please enter both username and room name.');
    }
  }
  console.log(owner_user_id,'owner_user_id');
   
  const handleCopy = async () => {
    try {
      setCopy(true)
      await navigator.clipboard.writeText(`${window.location.origin}/share/${connect_id}?share=true&owner_user_id=${Object.keys(owner_user_id).length!==0?owner_user_id.user_id:''}`);
      setTimeout(() => {
        setCopy(false)
      }, 2000)
    } catch {

    }
  }
useEffect(()=>{
  if(Object.keys(owner_user_id).length!==0){
  if(username){
    setuserName(username?.username)
  }
}
},[username])
console.log(owner_user_id,'Object.keys(owner_user_id)');

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="modal-box">

            <div class="container" onClick={toggleModal}>
              <button class="animated-button">Start connection</button>
            </div>
            {modalVisible && (
              <div className="modal fade show" style={{ display: 'block' }} role="dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    onClick={toggleModal}
                    aria-label="Close"
                  >
                    ×
                  </button>
                  <div className="modal-body">
                    <h3 className="title">{next?'Invite your partner':'Connect with your partner'}</h3>
                    {/* <p className="description">Login here using Email & Password</p> */}
                    {!next && (
                      <>
                         <div className="form-group">
                         <span className="input-icon" style={{top:"63%"}}>
                           <i className="fa fa-user"></i>
                         </span>
                         <input
                           type="name"
                           className="form-control"
                           placeholder="Enter your name"
                                  value={user_name}
                         onChange={(e) => setuserName(e.target.value)}
                         />
                         
                         </div>
                      {user_name.trim().length !== 0 && (
                         <button className="btn" onClick={handleNext}>Next</button>

                        )}
                      </>
                    )}


                  </div>
                  {next && (
                    <>
                      <div style={{ display: "flex" }}>
                        <input
                          type="link"
                          className="form-control"
                          placeholder="Link"
                          disabled
                          value={`${window.location.origin}/share/${connect_id}?share=true&owner_user_id=${Object.keys(owner_user_id).length!==0?owner_user_id.user_id:''}`}
                        />
                        <div style={{ marginLeft: "18px" }}>
                          <div className="icon-container" style={{ backgroundColor: "#ccc" }} onClick={() => handleCopy()}>
                            <FaCopy className="icon" />
                            <span className="hover-text">{copy ? 'Copied!' : 'Copy'}</span>
                          </div>
                        </div>
                      </div>
                      <h4 className="title" style={{ fontSize: "12px",color:"black" }}>Copy the link and share it with your partner.</h4>
                      <button className="btn" onClick={handleNext2}>Connect</button>

                    </>
                  )}


                </div>

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Connect;
