import React, { useEffect, useState,useRef } from 'react';
import { FaCopy } from "react-icons/fa"
import io from 'socket.io-client';
import { useNavigate, useParams } from 'react-router-dom';
import { generateHybridUniqueId } from '../../Utiles';
import { useSelector } from 'react-redux';
// const socket = io(process.env.REACT_APP_SOCKET_URL);
const UrlOpenScreen = ({ setShare_screen, setUser_name,users,setMultiple_user,room_id }) => {
  const navigate = useNavigate()
   const socket = useRef();
   const owner_user_id = useSelector(state => state.myReducer.data)
   const username = useSelector(state => state.myReducer.data)
  const [modalVisible, setModalVisible] = useState(true);
  const [user_name, setuserName] = useState('');
  const [next, setNext] = useState(false)
  const [copy, setCopy] = useState(false)
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const handleUserName = (e) => {
    setuserName(e.target.value)
    setUser_name(e.target.value)
  }
  const handleNext = () => {
    if (user_name)
      setNext(true)
  }

  const { id } = useParams();
  const uniqe_id = generateHybridUniqueId()
  const [user_id, setUser_id] = useState(uniqe_id)
  let user_id_by_database=owner_user_id.user_id||''
  
  //   const user_create_id=generateHybridUniqueId()
  const handleNext2 = () => {
    if (user_name && id) {
     
      if(users.length==2 ||users.length>2){
        setMultiple_user(true)

      }else{
        socket.current = io(process.env.REACT_APP_SOCKET_URL);
        socket.current.emit('register', user_name, id,user_id,user_id_by_database); // Register the user
        socket.current.emit('joinRoom', id); // Join the room
        localStorage.setItem("user_id", user_id);
        setShare_screen('false')
      }
      //   navigate(`${window.location.href}&user_id=${user_create_id}`)
      // Navigate to Chat component
    } else {
      alert('Please enter both username and room name.');
    }
  }


  useEffect(()=>{
    if(Object.keys(owner_user_id).length!==0){
    if(username){
      setuserName(username?.username)
      setUser_name(username?.username)
    }
  }
  },[username])
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="modal-box">
            {modalVisible && (
              <div className="modal fade show" style={{ display: 'block' }} role="dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    onClick={toggleModal}
                    aria-label="Close"
                  >
                    ×
                  </button>
                  <div className="modal-body">
                    <h3 className="title">Connect with {room_id==users[0]?.id?users[0]?.username:''}</h3>
                    {/* <p className="description">Login here using Email & Password</p> */}
                    {!next && (
                      <>
                      <div className="form-group">
                        <span className="input-icon" >
                          <i className="fa fa-user"></i>
                        </span>
                        <input
                          type="name"
                          className="form-control"
                          placeholder="Enter your name"
                          value={user_name}
                          onChange={(e) => handleUserName(e)}
                        />
                       
                      </div>
                      {user_name.trim().length !== 0 && (
                          <button className="btn" style={{ marginTop: "9px" }} onClick={()=>handleNext2()}>Connect</button>

                        )}
                      </>
                    )}


                  </div>

                  {/* <button className="btn" onClick={handleNext2}>Connect with mrinmay</button> */}




                </div>

              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UrlOpenScreen;
