import React from "react";
import "./TopRight.css";
import { FaComments } from "react-icons/fa"; // Import an icon for chat
import Chat from "./chat.png"
const TopRightChat = ({toggleChatPopup,unseen_message}) => {
  return (
    <div className="top-right" onClick={toggleChatPopup}>
      <div className="icon-container">
        <img src={Chat} style={{width:"53px",height:"51px",borderRadius:"49px"}} title="Chat"/>
        {/* <FaComments className="icon" /> */}
        {/* <span className="hover-text">Chat with Us</span> */}
        <div className={unseen_message.length!==0?"notification-badge":''}>{unseen_message.length!==0?(unseen_message.length>10?'10+':unseen_message.length):''}</div>
      </div>
    </div>
  );
};

export default TopRightChat;
