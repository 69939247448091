import React from 'react'
import "./About.css"
export default function About() {
    return (
        <div class="container2">
        <h1>Welcome to Friship</h1>
        <p>A unique platform designed to bring partners closer through fun, thought-provoking, and meaningful conversations. We believe that connection thrives on understanding, and our software is here to spark those "aha" moments in your relationship.</p>
        <p>Whether you're exploring each other’s personalities, revisiting shared memories, or diving into deep topics you’ve never touched before, Friship is the perfect companion for strengthening your bond.</p>
        <div class="features">
            <h2>Our platform features:</h2>
            <ul>
                <li>Thoughtful Questions: Curated prompts that encourage open communication and personal growth.</li>
                <li>Fun and Engaging Gameplay: A dynamic, interactive format that makes learning about each other exciting.</li>
                <li>Customized Experiences: Tailored questions based on your relationship stage and preferences.</li>
            </ul>
        </div>
        <div class="cta" onClick={() => window.open('/', '_blank', 'noopener,noreferrer')}>
            <button>Let’s Play, Talk, and Connect</button>
        </div>
        </div>
    )
}
