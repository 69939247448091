import React, { useEffect, useState } from "react";
import "./Toster.css"; // Optional: Add styles in a separate CSS file or inline

const TosterMessage = ({users,user_name,room_id}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const user_name2 = queryParams.get("user_name");
 
  
  const [toasts, setToasts] = useState([]);
  const[total_users,setTotal_users]=useState([])
  useEffect(()=>{
    if(user_name2){
    if(users.length===2){
      let data=users.filter((item)=>item.username!==user_name2)
      setTotal_users(data)
    }
   
  } if(user_name){
    if(users.length>1){
      let data=users.filter((item)=>item.username!==user_name)
      setTotal_users(data)
    }
  } 
  },[users,user_name2,user_name])
  console.log(user_name2,'user_name2',user_name,total_users);
  useEffect(()=>{
    if(total_users[0]?.id==room_id){
    if(user_name2){
    // if(users.length>1){
    // if(users.filter((item)=>item.username===total_users[0]?.username).length==0){
      if(total_users[0]?.username!==undefined){
        showToast(`${total_users[0]?.username} disconnect with you`)
      // }
      // }
    // }
  }
}if(user_name){
  if(total_users[0]?.username!==undefined){
    showToast(`${total_users[0]?.username} disconnect with you`)
  }
}
}
  },[users.length])
  const showToast = (message) => {
    const id = Date.now(); // Unique ID for each toast
    const newToast = { id, message };

    // Add the new toast to the array
    setToasts((prevToasts) => [...prevToasts, newToast]);

    // Remove the toast after 9 seconds
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 9000);
  };
  
console.log("My users",total_users,users);

 useEffect(()=>{
  if(Array.isArray(users)){
    if(users.length==2 && user_name!==users[1].username){
      if(users[1]?.id==room_id){
      showToast(`${users[1].username} connect with you`)
    }
  }
  }
 },[Array.isArray(users)&& users.length])
  return (
    <div>
      {/* <button onClick={() => showToast("This is a 9-second notification!")}>
        Show Notification
      </button> */}

      <div className="toaster-container">
        {toasts.map((toast) => (
          <div key={toast.id} className="toast">
            {toast.message}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TosterMessage;
