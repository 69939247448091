import { Helmet } from "react-helmet";

function SEOComponent({ title, description, url }) {
  return (
    <Helmet>
      {/* Page Title */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph (OG) for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content="https://friship.com/og-image.jpg" />

      {/* Twitter Card for Social Sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://friship.com/twitter-image.jpg" />

      {/* Canonical URL (Good for SEO) */}
      <link rel="canonical" href={url} />
    </Helmet>
  );
}

export default SEOComponent;
