export const AIQuestions=()=>{
    let data=[
        {"question": "What is your favorite color?", "OptionA": "Red", "OptionB": "Blue"},
    {"question": "Do you prefer tea or coffee?", "OptionA": "Tea", "OptionB": "Coffee"},
    {"question": "Are you a morning person?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer summer or winter?", "OptionA": "Summer", "OptionB": "Winter"},
    {"question": "Which pet do you like more?", "OptionA": "Dog", "OptionB": "Cat"},
    {"question": "Do you enjoy reading books?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like traveling?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Which do you prefer: mountains or beaches?", "OptionA": "Mountains", "OptionB": "Beaches"},
    {"question": "Do you like pizza?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you into sports?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer cats or dogs?", "OptionA": "Cats", "OptionB": "Dogs"},
    {"question": "Would you rather stay at home or go out?", "OptionA": "Stay at home", "OptionB": "Go out"},
    {"question": "Do you enjoy watching movies?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like listening to music?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you a night owl?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer hot or cold drinks?", "OptionA": "Hot", "OptionB": "Cold"},
    {"question": "Which do you prefer: books or movies?", "OptionA": "Books", "OptionB": "Movies"},
    {"question": "Do you like hiking?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like cooking?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer chocolate or vanilla?", "OptionA": "Chocolate", "OptionB": "Vanilla"},
    {"question": "Which is better: early mornings or late nights?", "OptionA": "Early mornings", "OptionB": "Late nights"},
    {"question": "Do you like playing video games?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you a planner or spontaneous?", "OptionA": "Planner", "OptionB": "Spontaneous"},
    {"question": "Which is better: city or countryside?", "OptionA": "City", "OptionB": "Countryside"},
    {"question": "Do you enjoy taking naps?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you a patient person?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy shopping?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you prefer to live in a house or an apartment?", "OptionA": "House", "OptionB": "Apartment"},
    {"question": "Do you like board games?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer texting or calling?", "OptionA": "Texting", "OptionB": "Calling"},
    {"question": "Do you enjoy sports games?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you a good dancer?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer casual or formal attire?", "OptionA": "Casual", "OptionB": "Formal"},
    {"question": "Do you like spending time with friends?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather visit the past or the future?", "OptionA": "Past", "OptionB": "Future"},
    {"question": "Are you an optimist?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like surprises?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Which is better: books or podcasts?", "OptionA": "Books", "OptionB": "Podcasts"},
    {"question": "Do you enjoy outdoor activities?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer ice cream or cake?", "OptionA": "Ice cream", "OptionB": "Cake"},
    {"question": "Are you a perfectionist?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like fast food?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather be rich or famous?", "OptionA": "Rich", "OptionB": "Famous"},
    {"question": "Are you an introvert?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like winter sports?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy gardening?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer to be early or late?", "OptionA": "Early", "OptionB": "Late"},
    {"question": "Would you rather read fiction or nonfiction?", "OptionA": "Fiction", "OptionB": "Nonfiction"},
    {"question": "Are you a foodie?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like to try new foods?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy the beach?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you into DIY projects?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy art?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Are you into photography?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather have a big house or a small house?", "OptionA": "Big house", "OptionB": "Small house"},
    {"question": "Do you prefer writing by hand or typing?", "OptionA": "Writing by hand", "OptionB": "Typing"},
    {"question": "Do you prefer quiet or noisy places?", "OptionA": "Quiet", "OptionB": "Noisy"},
    {"question": "Do you like to exercise?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like science fiction movies?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather live in a small town or a big city?", "OptionA": "Small town", "OptionB": "Big city"},
    {"question": "Do you enjoy puzzles?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer hot or cold weather?", "OptionA": "Hot", "OptionB": "Cold"},
    {"question": "Are you a creative person?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather have a job you love or a job that pays well?", "OptionA": "Job I love", "OptionB": "Job that pays well"},
    {"question": "Do you prefer fiction or nonfiction books?", "OptionA": "Fiction", "OptionB": "Nonfiction"},
    {"question": "Do you like winter or summer more?", "OptionA": "Winter", "OptionB": "Summer"},
    {"question": "Do you prefer working alone or in a team?", "OptionA": "Alone", "OptionB": "In a team"},
    {"question": "Are you a risk taker?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy outdoor sports?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather watch a comedy or a drama?", "OptionA": "Comedy", "OptionB": "Drama"},
    {"question": "Do you prefer reality or fiction?", "OptionA": "Reality", "OptionB": "Fiction"},
    {"question": "Are you a dog person?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like traveling to new places?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather spend the day reading or watching TV?", "OptionA": "Reading", "OptionB": "Watching TV"},
    {"question": "Do you like to try new hobbies?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer using a phone or a laptop?", "OptionA": "Phone", "OptionB": "Laptop"},
    {"question": "Do you enjoy watching documentaries?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather be a famous actor or musician?", "OptionA": "Actor", "OptionB": "Musician"},
    {"question": "Do you prefer salty or sweet snacks?", "OptionA": "Salty", "OptionB": "Sweet"},
    {"question": "Would you rather be able to fly or be invisible?", "OptionA": "Fly", "OptionB": "Invisible"},
    {"question": "Do you prefer listening to podcasts or audiobooks?", "OptionA": "Podcasts", "OptionB": "Audiobooks"},
    {"question": "Do you enjoy watching sports?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you prefer a road trip or a flight to your destination?", "OptionA": "Road trip", "OptionB": "Flight"},
    {"question": "Are you a morning or evening workout person?", "OptionA": "Morning", "OptionB": "Evening"},
    {"question": "Would you rather be in a group or by yourself?", "OptionA": "Group", "OptionB": "By myself"},
    {"question": "Do you prefer to be at home or out with friends?", "OptionA": "At home", "OptionB": "Out with friends"},
    {"question": "Would you prefer to live by the beach or in the mountains?", "OptionA": "Beach", "OptionB": "Mountains"},
    {"question": "Do you like surprises?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like to be organized?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy holiday decorations?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like to keep a journal?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer simple or complex recipes?", "OptionA": "Simple", "OptionB": "Complex"},
    {"question": "Do you enjoy learning history?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer online shopping or in-store shopping?", "OptionA": "Online", "OptionB": "In-store"},
    {"question": "Are you good at cooking?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you enjoy rainy days?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like spending time in nature?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather own a cat or a dog?", "OptionA": "Cat", "OptionB": "Dog"},
    {"question": "Do you like snow?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather explore a cave or climb a mountain?", "OptionA": "Explore a cave", "OptionB": "Climb a mountain"},
    {"question": "Do you enjoy meeting new people?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you prefer fiction or nonfiction?", "OptionA": "Fiction", "OptionB": "Nonfiction"},
    {"question": "Do you enjoy technology?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather watch a live play or a movie?", "OptionA": "Live play", "OptionB": "Movie"},
    {"question": "Do you enjoy writing?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Do you like traveling alone?", "OptionA": "Yes", "OptionB": "No"},
    {"question": "Would you rather read a physical book or an e-book?", "OptionA": "Physical book", "OptionB": "E-book"},
    {"question": "Do you enjoy exercising?", "OptionA": "Yes", "OptionB": "No"}

    ]
    return data
}