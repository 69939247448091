export const Question_data=()=>{
    let data=[
        {
            "question": "What is your favorite season?",
            "OptionA": "Summer",
            "OptionB": "Winter"
          },
          {
            "question": "Which pet do you prefer?",
            "OptionA": "Dog",
            "OptionB": "Cat"
          },
          {
            "question": "What type of movies do you like more?",
            "OptionA": "Action",
            "OptionB": "Comedy"
          },
          {
            "question": "Which type of vacation do you enjoy?",
            "OptionA": "Beach",
            "OptionB": "Mountains"
          },
          {
            "question": "How do you like your coffee?",
            "OptionA": "Black",
            "OptionB": "With milk & sugar"
          }
    ]
    return data
}