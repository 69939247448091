import React, { useEffect, useState } from "react";
import "./QuestionCreate.css";
import { userAllPost, userNewPost } from "../../AllApis/ApiIntegrate";
import { useSelector } from "react-redux";
import InformationIcon from "../LoginPage/Information.png"
import { AIQuestions } from "../DemoQuestion/AIQuestion";
const Question = ({ setModalVisible }) => {
  const user_id = useSelector(state => state.myReducer.data?.user_id)
  const [formVisible, setFormVisible] = useState(false);
  const [formAnimation, setFormAnimation] = useState(false); // For animation class
  const [questions, setQuestions] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [question, setQuestion] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [newQuestionIndex, setNewQuestionIndex] = useState(null); // Track newly added question index
  const [status, setStatus] = useState(true)
  const question_data=AIQuestions()
  const toggleForm = () => {
    if (formVisible) {
      setFormAnimation(true); // Trigger slide-up animation
      setTimeout(() => setFormVisible(false), 500); // Wait for animation to finish
    } else {
      setFormVisible(true);
      setFormAnimation(false);
    }
  };
  const [validation, setValidation] = useState(false)
  const [validation_message, setValidation_message] = useState('')
  const [view_ai, setViewAI] = useState(true)
  const addQuestion = () => {
    if (!question && !optionA && !optionB) {
      setValidation(true)
      setValidation_message('All fields must be filled out.')
    }
    if (optionA && optionB) {
      if (optionA.trim() == optionB.trim()) {
        setValidation(true)
        setValidation_message('Create a different option.')
      }
    }
    if (question && optionA && optionB && (optionA.trim() !== optionB.trim())) {
      const newQuestion = { question, OptionA: optionA, OptionB: optionB };
      setQuestions([...questions, newQuestion]);
      setQuestions2([...questions2, newQuestion]);
      setNewQuestionIndex(questions.length); // Set index of the new question
      setQuestion("");
      setOptionA("");
      setOptionB("");
      setViewAI(false)
      // setStatus(true)

      // Reset animation class after a short delay
      setTimeout(() => setNewQuestionIndex(null), 1000);
    }
  };
  useEffect(() => {
    if (validation) {
      if (optionA && optionB) {
        if (optionA.trim() == optionB.trim()) {
          setValidation(true)
          setValidation_message('Create a different option.')
        }
      }
      if (question && optionA && optionB && (optionA.trim() !== optionB.trim())) {
        setValidation_message('')
        setValidation(false)
      }

    }
  }, [question, optionA, optionB, validation])
  const deleteQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
    setQuestions2(questions.filter((_, i) => i !== index));
    setStatus(false)
  };

  const json = JSON.stringify({
    userId: user_id,
    questions: questions2,
    status: status
  })

  const handleSave = async () => {
    try {
      let res = await userNewPost(json)
      if (res) {
        setModalVisible(false)
      }
    } catch (err) {

    }
  }

  const handleGetData = async () => {
    try {
      let res = await userAllPost(user_id)
      setQuestions(res.data.data.questions)
      console.log(res.data.data.questions
        , 'this is data');

    } catch (err) {

    }
  }

  useEffect(() => {
    handleGetData()
  }, [])


  function getRandomQuestions() {
    const shuffled = question_data.sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, 5); // Get the first 5 items
}
const randomQuestions = getRandomQuestions();
    console.log(randomQuestions);
const handleAI=()=>{
  setQuestions(randomQuestions);
      setQuestions2(randomQuestions);
}
  return (
    <div className="app">
      <div className="form-container">
        <h4 className="title" style={{ fontSize: "12px" }}>Create at least five questions.</h4>

        <div style={{ display: "flex" }}>

          <button className="toggle-button" onClick={toggleForm}>
            <span>➕</span> Add Question
          </button>
          {questions2.length >= 5 && (
            <button className="add-button" onClick={handleSave} style={{ marginLeft: "6px" }}>
              Save
            </button>
          )}
          {view_ai && (
            <button class="ai-button" style={{ marginLeft: "9px" }} onClick={()=>handleAI()}>AI</button>

          )}

        </div>
        {formVisible && (
          <div className={`form-fields ${formAnimation ? "hide" : ""}`}>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Write your question here..."
            />
            <div className="row">
              <input
                type="text"
                value={optionA}
                onChange={(e) => setOptionA(e.target.value)}
                placeholder="Option A"
              />
              <input
                type="text"
                value={optionB}
                onChange={(e) => setOptionB(e.target.value)}
                placeholder="Option B"
              />
            </div>
            <div>
              {validation && <>

                <>
                  <img src={InformationIcon} alt='' style={{ width: "16px", marginRight: "3px" }} />
                  <span style={{ color: "red" }}>{validation_message}</span>
                </>

              </>
              }
            </div>
            <button className="add-button" style={{ marginTop: "3px" }} onClick={addQuestion}>
              Add to List
            </button>
          </div>
        )}
      </div>
      <div className="question-list">
        <ul>
          {questions.map((q, index) => (
            <li
              key={index}
              className={`question-create-card ${index === newQuestionIndex ? "new-card" : ""
                }`}
            >
              <div className="question">Q: {q.question}</div>
              <div className="options">
                <div>A: {q.OptionA}</div>
                <div>B: {q.OptionB}</div>
              </div>
              <button className="delete" onClick={() => deleteQuestion(index)}>
                &times;
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Question;
