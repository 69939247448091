import React, { Component, useMemo, useState, useEffect, useRef } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import SEOComponent from "./Component/SEOComponent";
import Welcome from "./Component/WelcomeScreen/Welcome";
import ShareScreen from "./Pages/ShareScreen";
import { verifytoken } from "./AllApis/ApiIntegrate";
import { useSelector, useDispatch } from 'react-redux';
import { setData } from "./redux/action/LoginAction";
import Loader from "./Component/Loader/Loader";
import ShareScreendetails from "./Pages/ShareScreendetails";
import About from './Pages/About';
import Privacy from './Pages/Privacy'
import Feedback from './Pages/Feedback'


export default function AppRoutes() {
  const dispatch = useDispatch()
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [user_id, setUser_id] = useState("")
  const [appverify, setAppverify] = useState(false)
  const location = useLocation()
  const { pathname } = location
   const { id } = useParams();
  const navigate=useNavigate()
  console.log(pathname, 'pathname');
  useEffect(() => {
    const User_Token = async () => {
      try {
        let res = await verifytoken(token)
        if (res) {
          dispatch(setData(res.data))
          setUser_id(res.data.user_id)
          

          // navigate("/")
        }
      } catch (err) {
        setAppverify(true)
        localStorage.removeItem('user_id');
        localStorage.removeItem("token")
        // setToken("")

        // navigate("/login");

        // navigate("/register");

      }
    }
    if (token) {
      User_Token()
    }
  }, [token])




  useEffect(() => {
    if (
      pathname === "/about" ||
      pathname === "/privacy" ||
      pathname === "/feedback"
    ) {
      document.body.style.background = "";
      document.body.style.color = '#333';
      document.body.style.overflowY = 'scroll';
    } else {
      document.body.style.background = "linear-gradient(to right,rgba(255, 65, 97, 0.79), #FF4162)";
      document.body.style.color = 'white';
    }
    if (pathname === '/'||pathname==="") {
      let elements = document.querySelectorAll(".bottom-right");
      elements.forEach(element => {
        element.style.bottom = "83px";

      });

    }else{
      let elements = document.querySelectorAll(".bottom-right");
      elements.forEach(element => {
        element.style.bottom = "20px";

      });
    }
  }, [pathname]);

useEffect(()=>{
  if (
    pathname === "/share" ||
    pathname === "/share_details" 
  ){
    if(!id){
      navigate('/')
    }

  }
},[pathname,id])

  
  return (
    <div>
{/* 
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/share/:id" element={<ShareScreen />} />
        <Route path="/share_details/:user1/:user2/:score" element={<ShareScreendetails />} />
        <Route path="/friship/about" element={<About />} />
        <Route path="/friship/privacy" element={<Privacy />} />
        <Route path="/friship/feedback" element={<Feedback />} />
      </Routes> */}
     
    <Routes>
      <Route
        path="/"
        element={
          <>
            <SEOComponent
              title="Friship - Connect with Partner"
              description="Friship is a social networking site to connect with your partner and meet new people."
              url="https://friship.com"
            />
            <Welcome />
          </>
        }
      />
      <Route
        path="/home"
        element={
          <>
            <SEOComponent
              title="Friship - Connect with Partner"
              description="Friship is a social networking site to connect with your partner and meet new people."
              url="https://friship.com"
            />
            <Welcome />
          </>
        }
      />
    
      <Route
        path="/share/:id"
        element={
          <>
            <SEOComponent
              title="Share on Friship"
              description="Share and connect with your partner on Friship."
              url="https://friship.com/share"
            />
            <ShareScreen appverify={appverify}/>
          </>
        }
      />
      <Route
        path="/share_details/:id"
        element={
          <>
            <SEOComponent
              title="Friship - Share Details"
              description="View detailed friendship scores on Friship."
              url="https://friship.com/share_details"
            />
            <ShareScreendetails />
          </>
        }
      />
      <Route
        path="/about"
        element={
          <>
            <SEOComponent
              title="About Friship"
              description="Learn more about Friship, your favorite social network."
              url="https://friship.com/about"
            />
            <About />
          </>
        }
      />
      <Route
        path="/privacy"
        element={
          <>
            <SEOComponent
              title="Privacy Policy - Friship"
              description="Read Friship's privacy policy to understand how we protect your data."
              url="https://friship.com/privacy"
            />
            <Privacy />
          </>
        }
      />
      <Route
        path="/feedback"
        element={
          <>
            <SEOComponent
              title="Feedback - Friship"
              description="We value your feedback! Share your thoughts with us."
              url="https://friship.com/feedback"
            />
            <Feedback />
          </>
        }
      />
    </Routes>
 
    </div>
  )
}
