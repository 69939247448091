import axios from 'axios';

const apiUrl = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL, 
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token dynamically
apiUrl.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Get token from localStorage (or another secure storage)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Attach token to request headers
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiUrl;
