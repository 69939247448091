import React from 'react'
import './Loader.css'
export default function Loader({show}) {
  return (
    <div>
      <div class="loader">
        
        <div style={{flexDirection:"row" ,display:"flex",alignItems:"center"}}>
      {show!=='false' && <div class="text">Finding</div>}  
        <div class="dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        </div>
    </div>
    </div>
  )
}
